import React from "react"
import { TextField } from "@material-ui/core"
import { Autocomplete as MuiAutoComplete, AutocompleteProps as MuiAutoCompleteProps, Value } from "@material-ui/lab"
import { OnChange } from "../../Form"
import { Optional } from "../../../utils/types"

export type AutoCompleteProps<D, Multiple extends boolean | undefined = undefined> = Omit<
    Optional<MuiAutoCompleteProps<D, Multiple, false, false>, "renderInput">,
    "onChange"
> & {
    onChange: OnChange<Value<D, Multiple, false, false>>
    label?: string
    placeholder?: string
    error: string | undefined
    required?: boolean
}

const AutoComplete = <D, Multiple extends boolean | undefined = undefined>(props: AutoCompleteProps<D, Multiple>) => {
    const { multiple, label, placeholder, onChange, error, renderInput, required, value, ...rest } = props

    return (
        <MuiAutoComplete<D, Multiple, false, false>
            multiple={multiple}
            onChange={(_, value) => onChange(value)}
            id="multiple-select"
            value={value || undefined}
            {...rest}
            renderInput={
                renderInput ??
                ((params) => (
                    <TextField
                        required={required}
                        error={!!error}
                        helperText={error || ""}
                        label={label}
                        placeholder={placeholder}
                        {...params}
                    />
                ))
            }
        />
    )
}

export default AutoComplete
