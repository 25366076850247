import * as DateFns from "date-fns"
import nb from "date-fns/locale/nb"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

type FormatDate = (
    date: Date | MaterialUiPickersDate | string,
    format?: string,
    options?: {
        locale?: Locale
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        firstWeekContainsDate?: number
        useAdditionalWeekYearTokens?: boolean
        useAdditionalDayOfYearTokens?: boolean
    },
) => string

export const formatDate: FormatDate = (date, format = "d. MMMM yyyy", options = {}) => {
    let _date = date
    if (typeof date === "string") {
        _date = new Date(date)
    }
    const _options = { locale: nb, ...options }
    return DateFns.format(_date as Date, format, _options)
}
