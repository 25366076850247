import { defaultTheme } from "./odecon"
import { makeStyles } from "@material-ui/core/styles"

export const useGlobalStyles = makeStyles(() => ({
    "@global": {
        ".section": {
            marginRight: "auto",
            marginLeft: "auto",
            width: "100%",
            maxWidth: defaultTheme.breakpoints.values.lg,
            "&.full": {
                maxWidth: "unset !important",
            },
        },
        ".square": {
            paddingTop: "100%",
            position: "relative",
            "&> img": {
                objectFit: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        },
        ".imageShadow": {
            boxShadow: defaultTheme.shadows[6],
        },
        a: {
            color: "#88a7ff",
        },
    },
}))
