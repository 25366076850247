import React, { useCallback, useState } from "react"
import Router from "../../utils/Router"
import { IconButton, ListItem, makeStyles, Theme } from "@material-ui/core"
import cn from "classnames"
import { Person as PersonIcon, Close as CloseDrawerIcon, Menu as HamburgerIcon } from "@material-ui/icons"
import { useAuth0 } from "../../utils/Auth0Provider"
import Header, { headerHeight } from "../../components/Header"
import routes, { ViewRoute } from "../../routes"
import Menu from "../../components/Menu"
import Drawer from "../../components/Drawer"
import SubMenu from "../../components/SubMenu"
import { useIsSizeDown } from "../../utils/hooks"

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            height: "100%",
        },
        belowHeader: {
            marginTop: headerHeight,
            display: "flex",
            overflowX: "hidden",
        },
        content: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            display: "flex",
            padding: theme.spacing(2),
            height: "100%",
        },
    }
})

const AdminComponent = () => {
    const classes = useStyles()
    const { logout } = useAuth0()
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
    const HeaderDrawerIcon = isDrawerOpen ? CloseDrawerIcon : HamburgerIcon
    const isSmallSize = useIsSizeDown("md")

    const renderMenu = useCallback(() => {
        return (
            <SubMenu<ViewRoute>
                getIcon={(view) => view.icon}
                getLabel={(view) => view.title}
                getSubMenu={(view) => {
                    const views = view.views?.filter((subView) => !subView.removeFromMenu) || []
                    return views.length > 0 ? views : undefined
                }}
                getHref={(view) => "/" + view.path}
                parentHref={"/admin"}
                getIsLink={(view) => !!view.element}
            >
                {routes["admin"].views}
            </SubMenu>
        )
    }, [])

    return (
        <div className={classes.root}>
            <Header className={"section"}>
                {isSmallSize && (
                    <IconButton onClick={() => setIsDrawerOpen((prevOpen) => !prevOpen)}>
                        <HeaderDrawerIcon fontSize={"large"} />
                    </IconButton>
                )}
                <Menu Icon={PersonIcon} style={{ marginLeft: "auto" }}>
                    <ListItem button onClick={() => logout()}>
                        Log out
                    </ListItem>
                </Menu>
            </Header>
            <div className={classes.belowHeader}>
                {isSmallSize && (
                    <Drawer open={isDrawerOpen} setDrawerState={setIsDrawerOpen}>
                        {renderMenu()}
                    </Drawer>
                )}
                <main className={cn(classes.content, "section")}>
                    {!isSmallSize && renderMenu()}
                    <Router layout={"admin"} />
                </main>
            </div>
        </div>
    )
}

export default AdminComponent
