import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react"
import { CircularProgress } from "@material-ui/core"

export interface ViewContentProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    children?: ReactNode | ReactNode[]
    loading?: boolean
}

const ViewContent = ({ loading, ...props }: ViewContentProps) => {
    return loading ? <CircularProgress /> : <div {...props} />
}

export default ViewContent
