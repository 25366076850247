import React, { useEffect, useState } from "react"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import ViewContent from "../../components/View/ViewContent"
import { Person, usePersons } from "../../store/PersonStore"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import Table from "../../components/Table"
import PersonForm from "../../forms/PersonForm"
import Menu from "../../components/Menu"
import DotsIcon from "@material-ui/icons/MoreHoriz"
import DeleteIcon from "@material-ui/icons/Delete"
import { useNavigate } from "react-router"

const Persons = () => {
    const { getAllPersons, persons, removeOnePerson } = usePersons()
    const [isNewPersonDialogOpen, setIsNewPersonDialogOpen] = useState<boolean>(false)
    const theme = useTheme()
    const navigate = useNavigate()
    const isSmallSize = useMediaQuery(theme.breakpoints.down("xs"))

    useEffect(() => {
        getAllPersons()
    }, [getAllPersons])

    return (
        <>
            <View>
                <ViewHeader
                    title={"Personer"}
                    actions={
                        <Fab
                            color={"primary"}
                            variant={"extended"}
                            onClick={() => setIsNewPersonDialogOpen((oldValue) => !oldValue)}
                        >
                            <AddIcon />
                            <Typography>Legg til person</Typography>
                        </Fab>
                    }
                />
                <ViewContent>
                    <Table<Person>
                        data={persons}
                        onClickRow={(person) => navigate(person._id)}
                        config={[
                            {
                                title: "Navn",
                                getValue: (person) => [person.first_name, person.last_name].join(" "),
                            },
                            {
                                title: "E-post",
                                getValue: (person) => person.mail,
                            },
                            {
                                title: "Telefon",
                                getValue: (person) => person.phone,
                            },
                            {
                                title: "",
                                getValue: (person) => (
                                    <Menu size={"small"} Icon={DotsIcon}>
                                        <ListItem button onClick={() => removeOnePerson(person._id)}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText>Slett</ListItemText>
                                        </ListItem>
                                    </Menu>
                                ),
                            },
                        ]}
                    />
                </ViewContent>
            </View>
            <Dialog
                fullWidth
                fullScreen={isSmallSize}
                open={isNewPersonDialogOpen}
                onClose={() => setIsNewPersonDialogOpen(false)}
            >
                <DialogTitle>Ny person</DialogTitle>
                <DialogContent>
                    <PersonForm onSubmitted={() => setIsNewPersonDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Persons
