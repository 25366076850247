import React, { ReactNode } from "react"
import {
    TableProps as MuiTableProps,
    Theme,
    Table as MuiTable,
    TableHead,
    TableCell,
    TableRow,
    makeStyles,
    TableBody,
    TableHeadProps,
    TableRowProps,
} from "@material-ui/core"
import cn from "classnames"

export interface TableProps<D extends object> extends MuiTableProps {
    data?: D[]
    config: {
        title: string
        getValue: (dataObject: D) => string | number | undefined | boolean | ReactNode
        minWidth?: number | string
        width?: number | string
    }[]
    className?: string
    onClickRow?: (rowElement: D) => void
    headProps?: TableHeadProps
    rowProps?: Omit<TableRowProps, "onClick">
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    tableCell: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    tableRowClickable: {
        "&:hover": {
            backgroundColor: theme.palette.type === "dark" ? "#454545" : "#e5e5e5",
        },
        cursor: "pointer",
    },
    headCell: {
        fontWeight: "bold",
    },
}))

function Table<D extends object>(props: TableProps<D>) {
    const { data, config, className, onClickRow, headProps, rowProps } = props
    const classes = useStyles()
    return (
        <MuiTable className={className}>
            <TableHead {...headProps}>
                <TableRow>
                    {config &&
                        config.map((col, i) => {
                            return (
                                <TableCell
                                    className={cn(classes.tableCell, classes.headCell)}
                                    style={{
                                        ...(col.minWidth && {
                                            minWidth: col.minWidth,
                                        }),
                                        ...(col.width && {
                                            width: col.width,
                                        }),
                                    }}
                                    key={i}
                                >
                                    {col.title}
                                </TableCell>
                            )
                        })}
                </TableRow>
            </TableHead>
            <TableBody>
                {data &&
                    data.map((row, i) => {
                        return (
                            <TableRow
                                key={i}
                                onClick={() => onClickRow && onClickRow(row)}
                                {...rowProps}
                                className={cn(
                                    {
                                        [classes.tableRowClickable]: !!onClickRow,
                                    },
                                    rowProps?.className,
                                )}
                            >
                                {config.map((col, j) => (
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{
                                            ...(col.minWidth && {
                                                minWidth: col.minWidth,
                                            }),
                                            ...(col.width && {
                                                width: col.width,
                                            }),
                                        }}
                                        key={j}
                                    >
                                        {col.getValue(row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
            </TableBody>
        </MuiTable>
    )
}

export default Table
