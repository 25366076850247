import React from "react"
import { Card, Fab, makeStyles, Theme, Typography } from "@material-ui/core"
import LandingBackground from "../../assets/img/stock-photos/close-up-computer.jpg"
import { useAuth0 } from "../../utils/Auth0Provider"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        padding: theme.spacing(4),
        backgroundImage: `url(${LandingBackground})`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(4),
        "& h1": {
            fontSize: 56,
        },
    },
}))

const Landing = () => {
    const classes = useStyles()
    const { isAuthenticated } = useAuth0()

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <Typography align={"center"} gutterBottom variant={"h1"}>
                    Odecon CRM
                </Typography>
                <Fab variant={"extended"} color={"primary"} component={Link} to={"/login"}>
                    {isAuthenticated ? "Gå til dashboard" : "Logg inn"}
                </Fab>
            </Card>
        </div>
    )
}

export default Landing
