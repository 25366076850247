import React, { CSSProperties, useState } from "react"
import {
    Collapse,
    IconButton,
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Theme,
} from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons"
import cn from "classnames"

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        openSubMenuArrow: {
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.complex,
            }),
            "&.open": {
                transform: "rotate(90deg)",
            },
        },
        listItem: {
            paddingLeft: 20,
        },
        listItemSelected: {
            color: theme.palette.primary.main,
            "& > .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
            },
        },
    }
})

interface DefaultMenuItemType {
    key: string
    label: string
    subMenu: DefaultMenuItemType[]
}

export interface SubMenuProps<MenuItemType = DefaultMenuItemType> {
    children?: MenuItemType[]
    getLabel?: (menuItem: MenuItemType) => string | undefined
    getSubMenu?: (menuItem: MenuItemType) => MenuItemType[] | undefined
    getIcon?: (menuItem: MenuItemType) => typeof SvgIcon | undefined
    getHref?: (menuItem: MenuItemType) => string
    parentHref?: string
    getIsLink?: (menuItem: MenuItemType) => boolean
    offset?: number
    style?: CSSProperties
}

const SubMenu = <MenuItemType extends {}>(props: SubMenuProps<MenuItemType>) => {
    const { style, getLabel, getSubMenu, children, getIcon, getHref, parentHref, getIsLink, offset = 0 } = props
    const classes = useStyles()
    const [openSubMenu, setOpenSubMenu] = useState<number | undefined>()
    return (
        <List className={classes.root} style={{ ...style, minWidth: 200 }}>
            {children?.map((menuItem, i) => {
                const label = getLabel?.(menuItem)
                const icon = getIcon?.(menuItem)
                const href = getHref ? parentHref + getHref(menuItem) : undefined
                const isLink = getIsLink?.(menuItem)
                const subMenu = getSubMenu?.(menuItem)

                return (
                    <React.Fragment key={i}>
                        <ListItem
                            dense
                            alignItems={"center"}
                            className={cn(classes.listItem)}
                            classes={{ selected: classes.listItemSelected }}
                            onClick={() => {
                                subMenu &&
                                    !isLink &&
                                    setOpenSubMenu((oldOpenSubMenu) => (oldOpenSubMenu === i ? undefined : i))
                            }}
                            key={i}
                            component={isLink ? NavLink : "div"}
                            activeClassName={classes.listItemSelected}
                            to={href}
                            button={(isLink || !!subMenu) as true}
                        >
                            {!!icon && (
                                <ListItemIcon>
                                    <SvgIcon fontSize={"small"} component={icon} />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                style={{
                                    paddingLeft: 72 * (!!icon ? offset - 1 : offset),
                                }}
                            >
                                {label}
                            </ListItemText>
                            {subMenu && (
                                <IconButton
                                    size={"small"}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setOpenSubMenu((oldOpenSubMenu) => (oldOpenSubMenu === i ? undefined : i))
                                    }}
                                    style={{ justifyContent: "flex-end" }}
                                >
                                    <ArrowRightIcon
                                        className={cn(classes.openSubMenuArrow, { open: openSubMenu === i })}
                                    />
                                </IconButton>
                            )}
                        </ListItem>
                        {subMenu && (
                            <Collapse in={openSubMenu === i} unmountOnExit={false}>
                                <SubMenu {...props} parentHref={href} offset={offset + 1} style={undefined}>
                                    {subMenu}
                                </SubMenu>
                            </Collapse>
                        )}
                    </React.Fragment>
                )
            })}
        </List>
    )
}

export default SubMenu
