import React, { createContext, ReactNode, useContext } from "react"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"

export interface Person {
    _id: string
    first_name: string
    last_name: string
    mail?: string
    phone?: string
}

export interface PersonsContextType extends BaseContext {
    persons: Person[]
    personsById: { [id in string]: Person }
    addPerson: AddOne<Person>
    updatePerson: UpdateOne<Person>
    getOnePerson: GetOne
    getAllPersons: GetAll
    removeOnePerson: RemoveOne
}

export const PERSONS_PATH = "/persons"

const PersonsContext = createContext<PersonsContextType>({
    loading: false,
    persons: [],
    personsById: {},
    addPerson: defaultAddOne,
    getAllPersons: defaultGetAll,
    getOnePerson: defaultGetOne,
    removeOnePerson: defaultRemoveOne,
    updatePerson: defaultUpdateOne,
})

export const usePersons = () => useContext(PersonsContext)

const PersonsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { dataById, data, removeOne, meta, loading, error, addOne, getOne, getAll, updateOne } = useStoreProvider<
        Person
    >({ url: PERSONS_PATH })

    return (
        <PersonsContext.Provider
            value={{
                meta,
                error,
                loading,
                personsById: dataById,
                persons: data,
                addPerson: addOne,
                getAllPersons: getAll,
                getOnePerson: getOne,
                removeOnePerson: removeOne,
                updatePerson: updateOne,
            }}
        >
            {children}
        </PersonsContext.Provider>
    )
}

export default PersonsProvider
