import React from "react"
import { ReactNode } from "react"
import PersonsProvider from "./PersonStore"
import EmployeesProvider from "./EmployeeStore"
import RolesProvider from "./RoleStore"
import PermissionsProvider from "./PermissionStore"
import ClientsProvider from "./ClientStore"
import ProjectsProvider from "./ProjectStore"
import TemplatesProvider from "./TemplateStore"

export interface StoreProps {
    children: ReactNode | ReactNode[]
}

const Store = (props: StoreProps) => {
    const { children } = props

    return (
        <PersonsProvider>
            <EmployeesProvider>
                <RolesProvider>
                    <ClientsProvider>
                        <ProjectsProvider>
                            <TemplatesProvider>
                                <PermissionsProvider>{children}</PermissionsProvider>
                            </TemplatesProvider>
                        </ProjectsProvider>
                    </ClientsProvider>
                </RolesProvider>
            </EmployeesProvider>
        </PersonsProvider>
    )
}

export default Store
