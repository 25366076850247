import firebase from "firebase/app"

// Load individual firebase clients here,
// so that they are present in the "firebase" property:
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyBgmVeholXCGvF5PdhyTBw1_fyRAkgRZaQ",
    authDomain: "odecon-api-1603271805946.firebaseapp.com",
    databaseURL: "https://odecon-api-1603271805946.firebaseio.com",
    projectId: "odecon-api-1603271805946",
    storageBucket: "odecon-api-1603271805946.appspot.com",
    messagingSenderId: "11918230333",
    appId: "1:11918230333:web:abfa13fcd8c958a23bbb54",
}
firebase.initializeApp(firebaseConfig)

// Get a reference to the storage service, which is used to create references in your storage bucket
export const firebaseStorage = firebase.storage
export const firebaseAuth = firebase.auth
