import React, { ReactElement } from "react"

// @material-ui/icons
import {
    AssignmentInd as EmployeesIcon,
    Person as PersonIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
} from "@material-ui/icons"

//Views
import Dashboard from "./views/Dashboard"
import Landing from "./views/Landing"
import { SvgIcon } from "@material-ui/core"
import Settings from "./views/Settings"
import Persons from "./views/Persons"
import Employees from "./views/Employees"
import Employee from "./views/Employee"
import Login from "./views/Login"
import Roles from "./views/Roles"
import Person from "./views/Person"
import Clients from "./views/Clients"
import Client from "./views/Client"
import Projects from "./views/Projects"
import Project from "./views/Project"
import Templates from "./views/Templates"

export interface ViewRoute {
    title: string
    element?: ReactElement
    path: string
    secure?: boolean
    removeFromMenu?: boolean
    icon?: typeof SvgIcon
    views?: ViewRoute[]
}

export interface LayoutRoute {
    path: string
    secure?: boolean
    views: ViewRoute[]
}

export interface Routes {
    [layout: string]: LayoutRoute
}

const routes: Routes = {
    admin: {
        path: "admin",
        secure: true,
        views: [
            {
                path: "dashboard",
                title: "Dashboard",
                icon: DashboardIcon,
                element: <Dashboard />,
            },
            {
                path: "clients",
                title: "Klienter",
                icon: PersonIcon,
                element: <Clients />,
                views: [
                    {
                        path: ":id",
                        title: "",
                        element: <Client />,
                        removeFromMenu: true,
                    },
                ],
            },
            {
                path: "projects",
                title: "Prosjekter",
                icon: PersonIcon,
                element: <Projects />,
                views: [
                    {
                        path: ":id",
                        title: "",
                        element: <Project />,
                        removeFromMenu: true,
                    },
                ],
            },
            {
                path: "persons",
                title: "Personer",
                icon: PersonIcon,
                element: <Persons />,
                views: [
                    {
                        path: ":id",
                        title: "",
                        element: <Person />,
                        removeFromMenu: true,
                    },
                ],
            },
            {
                path: "employees",
                title: "Ansatte",
                icon: EmployeesIcon,
                element: <Employees />,
                views: [
                    {
                        path: ":id",
                        title: "",
                        element: <Employee />,
                        removeFromMenu: true,
                    },
                ],
            },
            {
                path: "settings",
                title: "Innstillinger",
                icon: SettingsIcon,
                element: <Settings />,
                views: [
                    {
                        path: "roles",
                        title: "Roller",
                        element: <Roles />,
                    },
                    {
                        path: "templates",
                        title: "Maler",
                        element: <Templates />,
                    },
                ],
            },
        ],
    },
    landing: {
        path: "",
        views: [
            {
                title: "Hjem",
                path: "",
                element: <Landing />,
            },
            {
                title: "Logg inn",
                path: "/login",
                element: <Login />,
            },
        ],
    },
}

export default routes
