import React from "react"
import StyleButton from "../Buttons/StyleButton"
import { ControlsComponentProps } from "./types"
import { makeStyles, Theme } from "@material-ui/core"

const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Monospace", style: "CODE" },
]

export type InlineStyleControlsProps = ControlsComponentProps

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        userSelect: "none",
    },
}))

const InlineStyleControls = (props: InlineStyleControlsProps) => {
    const { editorState, onToggle } = props
    const classes = useStyles()

    const currentStyle = editorState.getCurrentInlineStyle()

    return (
        <div className={classes.root}>
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </div>
    )
}

export default InlineStyleControls
