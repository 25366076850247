import React from "react"
import { useEffect } from "react"
import { useAuth0 } from "../../utils/Auth0Provider"
import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}))

const Login = () => {
    const { loginWithRedirect } = useAuth0()
    const classes = useStyles()
    useEffect(() => {
        loginWithRedirect({ appState: { targetUrl: "/admin/dashboard" } }).then((loginRes) => {
            console.log(loginRes)
        })
    }, [loginWithRedirect])

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    )
}

export default Login
