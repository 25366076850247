import React, { ReactNode } from "react"
import { Theme, makeStyles, Drawer as MuiDrawer, useTheme, useMediaQuery } from "@material-ui/core"
import cn from "classnames"
import { headerHeight } from "../Header"
import { defaultTheme } from "../../styles/themes/odecon"

export interface DrawerProps {
    open: boolean
    setDrawerState: (newState: boolean) => void
    minimized?: boolean
    children: ReactNode
    onHover?: () => void
}

export const drawerWidth = 240
export const smallDrawerWidth = defaultTheme.spacing(7.5)

const useStyles = makeStyles<Theme, DrawerProps>((theme: Theme) => {
    return {
        root: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                duration: theme.transitions.duration.complex,
            }),
            "&.minimized": {
                width: smallDrawerWidth,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            marginTop: headerHeight,
            transition: theme.transitions.create("width", {
                duration: theme.transitions.duration.complex,
            }),
            "&.minimized": {
                width: smallDrawerWidth,
                "&:hover": {
                    width: drawerWidth,
                },
            },
        },
    }
})

const Drawer = (props: DrawerProps) => {
    const { children, open, setDrawerState } = props
    const classes = useStyles(props)
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("xs"))
    const minimized = !open && !isSmall
    return (
        <MuiDrawer
            className={cn(classes.root, { minimized })}
            classes={{ paper: cn(classes.drawerPaper, { minimized }) }}
            open={open}
            onClose={() => setDrawerState(false)}
            variant={isSmall ? "temporary" : "permanent"}
        >
            {children}
        </MuiDrawer>
    )
}

export default Drawer
