import React, { useEffect, useState } from "react"
import { useProjects } from "../../store/ProjectStore"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core"
import { useNavigate } from "react-router"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import { Add as AddIcon, MoreHoriz as DotsIcon, Delete as DeleteIcon } from "@material-ui/icons"
import ViewContent from "../../components/View/ViewContent"
import Table from "../../components/Table"
import { Project } from "../../store/ProjectStore"
import Menu from "../../components/Menu"
import ProjectForm from "../../forms/ProjectForm"

const Projects = () => {
    const { getAllProjects, projects, removeOneProject } = useProjects()
    const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState<boolean>(false)
    const theme = useTheme()
    const navigate = useNavigate()
    const isSmallSize = useMediaQuery(theme.breakpoints.down("xs"))

    useEffect(() => {
        getAllProjects()
    }, [getAllProjects])

    return (
        <>
            <View>
                <ViewHeader
                    title={"Prosjekter"}
                    actions={
                        <Fab
                            color={"primary"}
                            variant={"extended"}
                            onClick={() => setIsNewProjectDialogOpen((oldValue) => !oldValue)}
                        >
                            <AddIcon />
                            <Typography>Legg til prosjekt</Typography>
                        </Fab>
                    }
                />
                <ViewContent>
                    <Table<Project>
                        data={projects}
                        onClickRow={(project) => navigate(project._id)}
                        config={[
                            {
                                title: "Navn",
                                getValue: (project) => project.name,
                            },
                            {
                                title: "Prosjekttype",
                                getValue: (project) => project.projectType,
                            },
                            {
                                title: "",
                                getValue: (project) => (
                                    <Menu size={"small"} Icon={DotsIcon}>
                                        <ListItem button onClick={() => removeOneProject(project._id)}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText>Slett</ListItemText>
                                        </ListItem>
                                    </Menu>
                                ),
                            },
                        ]}
                    />
                </ViewContent>
            </View>
            <Dialog
                fullWidth
                fullScreen={isSmallSize}
                open={isNewProjectDialogOpen}
                onClose={() => setIsNewProjectDialogOpen(false)}
            >
                <DialogTitle>Nytt prosjekt</DialogTitle>
                <DialogContent>
                    <ProjectForm onSubmitted={() => setIsNewProjectDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Projects
