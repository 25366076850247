import React, { ReactNode } from "react"
import { Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

export interface BasicInfoProps {
    title?: string
    subtitle?: string
    image?: { src?: string; alt?: string }
    actions?: ReactNode | ReactNode[]
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& >:not(:first-child)": {
            marginLeft: theme.spacing(3),
        },
        width: "100%",
    },
    image: {
        width: 92,
        height: 92,
        borderRadius: 184,
        border: `2px solid ${theme.palette.grey["500"]}`,
        objectFit: "cover",
        objectPosition: "top",
    },
    textualContent: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
}))

const BasicInfoHeader = (props: BasicInfoProps) => {
    const { title, image, subtitle, actions } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {image?.src && <img className={classes.image} src={image?.src} alt={image?.alt || "Basic info image"} />}
            <div className={classes.textualContent}>
                <div>
                    <Typography gutterBottom variant={"h1"}>
                        {title}
                    </Typography>
                    <Typography variant={"subtitle2"}>{subtitle}</Typography>
                </div>
                <div>{actions}</div>
            </div>
        </div>
    )
}

export default BasicInfoHeader
