import React, { ReactNode } from "react"
import { makeStyles } from "@material-ui/core"
import cn from "classnames"

export interface ViewProps {
    children: ReactNode | ReactNode[]
    className?: string
}

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}))

const View = (props: ViewProps) => {
    const { children, className } = props
    const classes = useStyles()
    return <div className={cn(classes.root, className)}>{children}</div>
}

export default View
