import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import View from "../../components/View"
import { usePersons } from "../../store/PersonStore"
import ViewContent from "../../components/View/ViewContent"
import { Button, CircularProgress, Link, IconButton, Paper, Tab, Tabs } from "@material-ui/core"
import { NavigateBefore as BackIcon } from "@material-ui/icons"
import TabPanel from "../../components/TabPanel"
import BasicInfoHeader from "../../components/BasicInfoHeader"
import { Phone as PhoneIcon, Mail as MailIcon } from "@material-ui/icons"
import { useIsSizeDown } from "../../utils/hooks"
import { Link as RouterLink } from "react-router-dom"

const Person = () => {
    const { personsById, getOnePerson, loading } = usePersons()
    const [tabValue, setTabValue] = useState<number>(0)
    const locationState = useLocation().state as { redirectTo?: string }
    const { id } = useParams()
    const person = personsById[id]

    const isSmall = useIsSizeDown("sm")

    useEffect(() => {
        getOnePerson(id)
    }, [getOnePerson, id])

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            <View>
                <Button component={RouterLink} to={locationState?.redirectTo ?? ".."}>
                    <BackIcon fontSize={"small"} style={{ marginRight: 12 }} />
                    Tilbake
                </Button>
                <BasicInfoHeader
                    title={[person?.first_name, person?.last_name].join(" ")}
                    actions={
                        <div style={{ marginTop: -8 }}>
                            {person?.phone && (
                                <Link target="_blank" component={IconButton} href={"tel:" + person.phone}>
                                    <PhoneIcon color={"secondary"} fontSize={!isSmall ? "large" : "default"} />
                                </Link>
                            )}
                            {person?.mail && (
                                <Link target="_blank" component={IconButton} href={"mailto:" + person.mail}>
                                    <MailIcon color={"secondary"} fontSize={!isSmall ? "large" : "default"} />
                                </Link>
                            )}
                        </div>
                    }
                />
                <ViewContent>
                    <Paper style={{ padding: "0 24px 24px 24px" }}>
                        <Tabs
                            indicatorColor={"primary"}
                            value={tabValue}
                            onChange={(e, newTabValue) => setTabValue(newTabValue)}
                            aria-label={"Navigation tabs"}
                        >
                            <Tab label={"Info"} />
                            <Tab label={"Historie"} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}></TabPanel>
                    </Paper>
                </ViewContent>
            </View>
        </>
    )
}

export default Person
