import React, { createContext, ReactNode, useContext } from "react"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"

export interface Permission {
    _id: string
    permission_name: string
    description: string
    resource_server_identifier?: string
    resource_server_name?: string
}

export interface PermissionsContextType extends BaseContext {
    permissions: Permission[]
    permissionsById: { [id in string]: Permission }
    addPermission: AddOne<Permission>
    updatePermission: UpdateOne<Permission>
    getOnePermission: GetOne
    getAllPermissions: GetAll<{ includePermissions?: boolean }>
    removeOnePermission: RemoveOne
}

export const PERMISSIONS_PATH = "/permissions"

const PermissionsContext = createContext<PermissionsContextType>({
    loading: false,
    permissions: [],
    permissionsById: {},
    addPermission: defaultAddOne,
    getAllPermissions: defaultGetAll,
    getOnePermission: defaultGetOne,
    removeOnePermission: defaultRemoveOne,
    updatePermission: defaultUpdateOne,
})

export const usePermissions = () => useContext(PermissionsContext)

const PermissionsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { dataById, data, removeOne, meta, loading, error, addOne, getOne, getAll, updateOne } = useStoreProvider<
        Permission
    >({ url: PERMISSIONS_PATH })
    return (
        <PermissionsContext.Provider
            value={{
                meta,
                error,
                loading,
                permissionsById: dataById,
                permissions: data,
                addPermission: addOne,
                getAllPermissions: getAll,
                getOnePermission: getOne,
                removeOnePermission: removeOne,
                updatePermission: updateOne,
            }}
        >
            {children}
        </PermissionsContext.Provider>
    )
}

export default PermissionsProvider
