import React, { CSSProperties, ReactNode } from "react"
import MuiMenu from "@material-ui/core/Menu"
import { SvgIcon } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

export interface MenuProps {
    children?: ReactNode | ReactNode[]
    Icon: typeof SvgIcon
    className?: string
    size?: "inherit" | "default" | "small" | "large"
    style?: CSSProperties
}

const Menu = (props: MenuProps) => {
    const { Icon, className, size, children, style } = props
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(null)
    const profileMenuOpen = Boolean(menuAnchor)
    return (
        <div className={className} style={style}>
            <IconButton
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    setMenuAnchor(e.currentTarget)
                }}
            >
                <SvgIcon fontSize={size || "large"} component={Icon} />
            </IconButton>
            <MuiMenu
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                getContentAnchorEl={null}
                id="profile-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={profileMenuOpen}
                onClick={(e) => {
                    e.stopPropagation()
                    setMenuAnchor(null)
                }}
                onClose={() => setMenuAnchor(null)}
                onExit={() => setMenuAnchor(null)}
            >
                {children}
            </MuiMenu>
        </div>
    )
}

export default Menu
