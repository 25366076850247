import React, { createContext, ReactNode, useContext } from "react"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"
import { Permission } from "./PermissionStore"
import axios from "axios"

export interface Role {
    _id: string
    name: string
    description: string
    permissions?: Permission[]
}

export type ADD_OR_REMOVE_PERMISSIONS_FROM_ROLE = (
    roleId: string,
    permissions: Permission[],
    method: "ADD" | "REMOVE",
) => Promise<void>

export interface RolesContextType extends BaseContext {
    roles: Role[]
    rolesById: { [id in string]: Role }
    addRole: AddOne<Role>
    updateRole: UpdateOne<Role>
    getOneRole: GetOne
    getAllRoles: GetAll<{ includePermissions?: boolean }>
    removeOneRole: RemoveOne
    addOrRemovePermissionsFromRole: ADD_OR_REMOVE_PERMISSIONS_FROM_ROLE
}

export const ROLES_PATH = "/roles"

const RolesContext = createContext<RolesContextType>({
    loading: false,
    roles: [],
    rolesById: {},
    addRole: defaultAddOne,
    getAllRoles: defaultGetAll,
    getOneRole: defaultGetOne,
    removeOneRole: defaultRemoveOne,
    updateRole: defaultUpdateOne,
    addOrRemovePermissionsFromRole: (roleId, permissions, method) =>
        Promise.reject(roleId + permissions.map((_) => _._id).join(",") + method),
})

export const useRoles = () => useContext(RolesContext)

const RolesProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const {
        dataById,
        data,
        removeOne,
        meta,
        loading,
        error,
        addOne,
        getOne,
        getAll,
        updateOne,
        setDataById,
    } = useStoreProvider<Role>({ url: ROLES_PATH })

    const addOrRemovePermissionsFromRole = (roleId: string, permissions: Permission[], method: "ADD" | "REMOVE") => {
        return axios.post(`${ROLES_PATH}/${roleId}/permissions`, { permissions, method }).then((res) => {
            const newRole = res.data
            setDataById((prevState) => {
                return {
                    ...prevState,
                    [roleId]: newRole,
                }
            })
        })
    }

    return (
        <RolesContext.Provider
            value={{
                meta,
                error,
                loading,
                rolesById: dataById,
                roles: data,
                addRole: addOne,
                getAllRoles: getAll,
                getOneRole: getOne,
                removeOneRole: removeOne,
                updateRole: updateOne,
                addOrRemovePermissionsFromRole,
            }}
        >
            {children}
        </RolesContext.Provider>
    )
}

export default RolesProvider
