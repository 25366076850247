import React from "react"
import { Button } from "@material-ui/core"
import View from "../../components/View/View"
import ViewHeader from "../../components/View/ViewHeader"
import ViewContent from "../../components/View/ViewContent"
import { useAuth0 } from "../../utils/Auth0Provider"

const Dashboard = () => {
    const { user, permissions, roles, isAuthenticated } = useAuth0()
    return (
        <View>
            <ViewHeader title={"Dashboard"} actions={<Button variant={"contained"}>Halla balla</Button>} />
            <ViewContent>
                Autentisert: {isAuthenticated ? " Ja" : "Nei"}
                <br />
                Bruker: {JSON.stringify(user)}
                <br />
                Permissions: {permissions.join(", ")}
                <br />
                Roles: {roles.join(", ")}
            </ViewContent>
        </View>
    )
}

export default Dashboard
