import React, { useEffect } from "react"
import { Route, useNavigate } from "react-router-dom"
import { useAuth0 } from "../Auth0Provider"
import { CircularProgress } from "@material-ui/core"
import { RouteProps } from "react-router"

const PrivateRoute = ({ path, ...rest }: RouteProps) => {
    const { loading, isAuthenticated } = useAuth0()
    const navigate = useNavigate()

    useEffect(() => {
        if (loading || isAuthenticated) {
            return
        }
        navigate("/login")
    }, [loading, isAuthenticated, navigate])

    return !loading && isAuthenticated ? <Route path={path} {...rest} /> : <CircularProgress />
}

export default PrivateRoute
