import { Person, usePersons } from "../store/PersonStore"
import { Grid } from "@material-ui/core"
import TextField from "../components/Fields/TextField"
import SubmitButton from "../components/Fields/SubmitButton"
import Form from "../components/Form"
import React from "react"
import PhoneField from "../components/Fields/PhoneField"

interface PersonFormProps {
    onSubmitted?: () => void
    initialValues?: Partial<Person>
}

const PersonForm = (props: PersonFormProps) => {
    const { onSubmitted, initialValues } = props
    const { addPerson } = usePersons()

    const onSubmit = (person: Person) => {
        return addPerson(person).then(() => {
            onSubmitted?.()
        })
    }

    return (
        <Form<Person> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("first_name")}
                            value={values.first_name}
                            error={errors.first_name}
                            fullWidth
                            name={"first_name"}
                            label={"Fornavn"}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("last_name")}
                            value={values.last_name}
                            error={errors.last_name}
                            fullWidth
                            name={"last_name"}
                            label={"Etternavn"}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("mail")}
                            value={values.mail}
                            error={errors.mail}
                            type={"email"}
                            fullWidth
                            name={"mail"}
                            label={"E-post"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneField
                            onChange={onChangeFor<string>("phone")}
                            value={values.phone}
                            error={errors.phone}
                            fullWidth
                            name={"phone"}
                            label={"Telefonnummer"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default PersonForm
