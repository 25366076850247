import { TextField } from "@material-ui/core"
import { DatePicker as MuiDatePicker } from "@material-ui/pickers"
import * as dateFns from "date-fns"
import React, { useState } from "react"
import { DatePickerProps as MuiDatePickerProps } from "@material-ui/pickers"
import { OnChange } from "../../Form"

export interface DatePickerProps extends Omit<MuiDatePickerProps, "onChange" | "value" | "error"> {
    onChange: OnChange<Date | null>
    value?: Date | null
    error?: string
}

let idCount = 0
const DatePicker = (props: DatePickerProps) => {
    const { name, onChange, value, error, ...rest } = props
    const [touched, setTouched] = useState(false)

    return (
        <MuiDatePicker
            id={`DatePicker-${name}-${idCount++}`}
            labelFunc={(date) => dateFns.format(date as Date, "dd. MMM. yyyy")}
            autoOk
            value={value}
            onChange={(date) => onChange(date)}
            error={touched && !!error}
            helperText={error}
            fullWidth
            onBlur={() => setTouched(true)}
            variant={"dialog"}
            TextFieldComponent={(textFieldProps) => <TextField {...textFieldProps} margin={"normal"} />}
            {...rest}
        />
    )
}

export default DatePicker
