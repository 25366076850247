import React, { useEffect, useState } from "react"
import { useTemplates } from "../../store/TemplateStore"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import { Add as AddIcon, MoreHoriz as DotsIcon, Delete as DeleteIcon } from "@material-ui/icons"
import ViewContent from "../../components/View/ViewContent"
import Table from "../../components/Table"
import { Template } from "../../store/TemplateStore"
import Menu from "../../components/Menu"
import TemplateForm from "../../forms/TemplateForm"

const Templates = () => {
    const { getAllTemplates, templates, removeOneTemplate } = useTemplates()
    const [isNewTemplateDialogOpen, setIsTemplateDialogOpen] = useState<boolean>(false)

    const [editingTemplate, setEditingTemplate] = useState<Template | undefined>()

    useEffect(() => {
        getAllTemplates()
    }, [getAllTemplates])

    return (
        <>
            <View>
                <ViewHeader
                    title={"Maler"}
                    actions={
                        <Fab
                            color={"primary"}
                            variant={"extended"}
                            onClick={() => setIsTemplateDialogOpen((oldValue) => !oldValue)}
                        >
                            <AddIcon />
                            <Typography>Ny mal</Typography>
                        </Fab>
                    }
                />
                <ViewContent>
                    <Table<Template>
                        data={templates}
                        onClickRow={(template) => {
                            setIsTemplateDialogOpen(true)
                            setEditingTemplate(template)
                        }}
                        config={[
                            {
                                title: "Navn",
                                getValue: (template) => template.name,
                            },
                            {
                                title: "",
                                getValue: (template) => (
                                    <Menu size={"small"} Icon={DotsIcon}>
                                        <ListItem button onClick={() => removeOneTemplate(template._id)}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText>Slett</ListItemText>
                                        </ListItem>
                                    </Menu>
                                ),
                            },
                        ]}
                    />
                </ViewContent>
            </View>
            <Dialog
                fullWidth
                fullScreen={true}
                open={isNewTemplateDialogOpen}
                onClose={() => {
                    setEditingTemplate(undefined)
                    setIsTemplateDialogOpen(false)
                }}
            >
                <DialogTitle>{editingTemplate?.name ?? "Ny mal"}</DialogTitle>
                <DialogContent>
                    <TemplateForm
                        initialValues={editingTemplate}
                        onSubmitted={() => {
                            setEditingTemplate(undefined)
                            setIsTemplateDialogOpen(false)
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Templates
