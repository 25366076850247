import React, { FormEvent, ReactNode, useState } from "react"
import { makeStyles } from "@material-ui/core"
import cn from "classnames"

export interface OnSubmitForm<D> {
    (formValues: D): Promise<void | D>
}

export interface FormProps<D extends DefaultFormObject<D>> {
    onSubmit: OnSubmitForm<D>
    children: (onChangeFor: OnChangeFor<D>, values: Partial<D>, errors: FormErrors<D>) => ReactNode
    initialValues?: Partial<D>
    validate?: Validate<Partial<D>>
    className?: string
    onValuesChange?: (values: Partial<D>) => void
}

export type OnChangeFor<D> = <P>(fieldName: keyof D) => OnChange<P | null>
export type OnChange<V> = (value: V | null) => void

export type FormValues = string | number | Date | null | boolean | undefined
type DefaultFormObject<D> = { [key in keyof D]: unknown }

export type FormErrors<D = DefaultFormObject<Record<string, unknown>>> = {
    [k in keyof D]?: string
}

export type Validate<D extends DefaultFormObject<D>> = (fields: D) => { [k in keyof D]?: string }

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
}))

/**
 * A form component to handle form field changes, validation and error handling.
 * Wrap this component around any form fields.
 */

const Form = <D extends DefaultFormObject<D>>(props: FormProps<D>) => {
    const { children, onSubmit, validate, initialValues = {}, onValuesChange, className } = props
    const classes = useStyles()
    const [values, setValues] = useState<Partial<D>>(initialValues || {})
    const [errors, setErrors] = useState<FormErrors<D>>({})

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onSubmit(values as D).catch((err) => {
            setErrors(err.error?.fields || {})
        })
    }

    const validateFields = (_values = values) => {
        setErrors(validate ? validate(_values) : {})
    }

    const onChangeFor: OnChangeFor<D> = <P,>(fieldName: keyof D) => (value: P | null) => {
        const newValues = { ...values, [fieldName]: value }
        setValues(newValues)
        onValuesChange?.(newValues)
        validateFields(values)
    }
    return (
        <form className={cn(classes.root, className)} onSubmit={_onSubmit}>
            {children(onChangeFor, values, errors)}
        </form>
    )
}

export default Form
