import React, { useEffect, useState } from "react"
import { Employee, useEmployees } from "../../store/EmployeeStore"
import { Role, useRoles } from "../../store/RoleStore"
import Form from "../../components/Form"
import { CircularProgress, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import SubmitButton from "../../components/Fields/SubmitButton"
import AutoComplete from "../../components/Fields/AutoComplete"
import { Check as SelectedIcon } from "@material-ui/icons"

export interface EmployeePermissionsProps {
    employee?: Employee
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.sm,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            marginBottom: theme.spacing(4),
        },
    },
    permissions: {
        marginLeft: -theme.spacing(1),
        "& .MuiPaper-root": {
            display: "inline-flex",
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    },
}))

const EmployeePermissions = (props: EmployeePermissionsProps) => {
    const { employee } = props
    const { roles, getAllRoles, ...roleStore } = useRoles()
    const { updateRoles, ...employeeStore } = useEmployees()
    const [newRoles, setNewRoles] = useState<Role[]>(employee?.roles || [])
    const classes = useStyles()

    useEffect(() => {
        getAllRoles({ includePermissions: true })
    }, [getAllRoles])

    useEffect(() => {
        setNewRoles(employee?.roles || [])
    }, [employee])

    const onSubmitRoles = (values: { roles: Role[] }) => {
        if (!!employee?._id) {
            return updateRoles(
                employee._id,
                values.roles.map((role) => role._id),
            )
        }
        return Promise.reject()
    }

    const onFormValuesChange = (formValues: Partial<{ roles: Role[] }>) => {
        setNewRoles(formValues.roles || [])
    }

    return roleStore.loading || employeeStore.loading || !employee?.roles ? (
        <CircularProgress />
    ) : (
        <div className={classes.root}>
            <Form<{ roles: Role[] }>
                onValuesChange={onFormValuesChange}
                className={classes.form}
                onSubmit={onSubmitRoles}
                initialValues={{ roles: employee?.roles || [] }}
            >
                {(onChangeFor, values, errors) => (
                    <>
                        <AutoComplete<Role, true>
                            multiple
                            onChange={onChangeFor<Role[]>("roles")}
                            value={values?.roles || []}
                            error={errors.roles}
                            label={"Roller"}
                            disableCloseOnSelect
                            fullWidth
                            options={roles}
                            getOptionSelected={(option, value) => option._id === value._id}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, state) => {
                                return (
                                    <>
                                        {option.name}
                                        {state.selected && <SelectedIcon style={{ marginLeft: 12 }} />}
                                    </>
                                )
                            }}
                            noOptionsText={`Søket ga ingen treff`}
                        />
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </>
                )}
            </Form>
            <Typography variant={"h2"}>Tilganger</Typography>
            <div className={classes.permissions}>
                {roles
                    .filter((role) => newRoles?.findIndex((employeeRole) => role._id === employeeRole._id) >= 0)
                    .reduce<string[]>((acc, currentRole) => {
                        const result = [...acc]
                        currentRole.permissions?.forEach((permission) => {
                            if (!result.includes(permission.description)) {
                                result.push(permission.description)
                            }
                        })
                        return result
                    }, [])
                    .map((permissionName, key) => {
                        return (
                            <Paper key={key} variant={"outlined"}>
                                {permissionName}
                            </Paper>
                        )
                    })}
            </div>
        </div>
    )
}

export default EmployeePermissions
