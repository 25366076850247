import { useMediaQuery, useTheme } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

export { useStoreProvider } from "./useStoreProvider"

export const useIsSmallSize = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down("xs"))
}

export const useIsSizeDown = (size: Breakpoint | number) => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down(size))
}
