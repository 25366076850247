import Form, { OnSubmitForm } from "../components/Form"
import TextField from "../components/Fields/TextField"
import React from "react"
import RichTextField from "../components/Fields/RichTextField"
import SubmitButton from "../components/Fields/SubmitButton"
import { Grid } from "@material-ui/core"

export interface MailFormValues {
    from?: string
    to?: string
    subject?: string
    body?: string
}

export interface SendMailFormProps {
    initialValues: MailFormValues
    onSubmit: OnSubmitForm<MailFormValues>
}

const SendMailForm = (props: SendMailFormProps) => {
    const { initialValues, onSubmit } = props
    console.log("rendered")
    return (
        <Form<MailFormValues> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={"Fra"}
                            fullWidth
                            onChange={onChangeFor<string>("from")}
                            value={values.from}
                            error={errors.from}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Til"}
                            fullWidth
                            onChange={onChangeFor<string>("to")}
                            value={values.to}
                            error={errors.to}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Emne"}
                            fullWidth
                            onChange={onChangeFor<string>("subject")}
                            value={values.subject}
                            error={errors.subject}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RichTextField
                            label={"Brødtekst"}
                            onChange={onChangeFor<string>("body")}
                            value={values.body}
                            error={errors.body}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", marginTop: 12 }}>
                        <SubmitButton errors={errors}>Send</SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default SendMailForm
