import React, { useEffect, useState } from "react"
import View from "../../components/View"
import ViewContent from "../../components/View/ViewContent"
import { useNavigate, useParams } from "react-router-dom"
import { Employee as EmployeesType, useEmployees } from "../../store/EmployeeStore"
import axios from "axios"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Theme,
} from "@material-ui/core"
import TabPanel from "../../components/TabPanel"
import { NavigateBefore as BackIcon, Edit as EditIcon, Email as SendMailIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import EmployeeInfo from "./EmployeeInfo"
import EmployeePermissions from "./EmployeePermissions"
import BasicInfoHeader from "../../components/BasicInfoHeader"
import EmployeeForm from "../../forms/EmployeeForm"
import { useIsSmallSize } from "../../utils/hooks"
import SendMailForm, { MailFormValues } from "../../forms/SendMailForm"

const useStyles = makeStyles((theme: Theme) => ({
    tabPanel: {
        marginTop: theme.spacing(2),
    },
}))
const Employee = () => {
    const { getOneEmployee, loading, employeesById } = useEmployees()
    const classes = useStyles()

    const { id } = useParams()
    const employee: EmployeesType | undefined = employeesById[id]

    const [tabValue, setTabValue] = useState<number>(0)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [isSendMailDialogOpen, setIsSendMailDialogOpen] = useState<boolean>(false)

    const isSmallSize = useIsSmallSize()

    const navigate = useNavigate()

    const sendMail = (mailFormValues: MailFormValues) => {
        return axios.post(`/communication/send/mail`, mailFormValues).then(() => {
            setIsSendMailDialogOpen(false)
        })
    }

    useEffect(() => {
        getOneEmployee(id)
    }, [getOneEmployee, id])

    useEffect(() => {
        document.title = `${employee?.given_name} – Odecon CRM`
    }, [employee])

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            <View>
                <Button onClick={() => navigate("..")}>
                    <BackIcon fontSize={"small"} style={{ marginRight: 12 }} />
                    Tilbake til ansatte
                </Button>
                <BasicInfoHeader
                    actions={
                        <>
                            <IconButton onClick={() => setIsSendMailDialogOpen(true)} color={"primary"}>
                                <SendMailIcon />
                            </IconButton>
                            <IconButton onClick={() => setIsDialogOpen(true)} color={"primary"}>
                                <EditIcon />
                            </IconButton>
                        </>
                    }
                    title={[employee?.given_name, employee?.family_name].join(" ")}
                    subtitle={employee?.position}
                    image={{ src: employee?.picture, alt: [employee?.given_name, employee?.family_name].join(" ") }}
                />
                <ViewContent>
                    <Paper style={{ padding: "0 24px 24px 24px" }}>
                        <Tabs
                            indicatorColor={"primary"}
                            value={tabValue}
                            onChange={(e, newTabValue) => setTabValue(newTabValue)}
                            aria-label={"Navigation tabs"}
                        >
                            <Tab label={"Info"} />
                            <Tab label={"Tilganger"} />
                            <Tab label={"Historie"} />
                        </Tabs>
                        <TabPanel className={classes.tabPanel} value={tabValue} index={0}>
                            <EmployeeInfo employee={employeesById[id]} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                            <EmployeePermissions employee={employee} />
                        </TabPanel>
                    </Paper>
                </ViewContent>
            </View>
            <Dialog fullWidth fullScreen={isSmallSize} open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Endre ansatt {employee?.given_name}</DialogTitle>
                <DialogContent>
                    <EmployeeForm
                        initialValues={employee}
                        onSubmitted={() => {
                            setIsDialogOpen(false)
                        }}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth
                fullScreen={isSmallSize}
                open={isSendMailDialogOpen}
                onClose={() => setIsSendMailDialogOpen(false)}
            >
                <DialogTitle>Send mail til {employee?.given_name}</DialogTitle>
                <DialogContent>
                    <SendMailForm initialValues={{ to: employee?.email }} onSubmit={sendMail} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Employee
