import React, { createContext, ReactNode, useCallback, useContext } from "react"
import { Auth0UserProfile } from "auth0-js"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import axios from "axios"
import { ApiResponseWithData } from "../utils/api/types"
import { Role } from "./RoleStore"
import { useStoreProvider } from "../utils/hooks"
import { Permission } from "./PermissionStore"

export type Employee = Omit<Auth0UserProfile, "user_id"> & {
    _id?: string
    position?: string
    phone?: string
    birthday?: Date
    roles?: Role[]
    permissions?: Permission[]
}

export type UpdateRoles = (id: string, roleIds: string[]) => Promise<void>
export type GetRolesForEmployee = (id: string) => Promise<Role[]>

export interface EmployeesContextType extends BaseContext {
    employees: Employee[]
    employeesById: { [id in string]: Employee }
    addEmployee: AddOne<Employee>
    updateEmployee: UpdateOne<Employee>
    getOneEmployee: GetOne
    getAllEmployees: GetAll
    removeOneEmployee: RemoveOne
    updateRoles: UpdateRoles
    getRolesForEmployee: GetRolesForEmployee
}

export const EMPLOYEES_PATH = "/employees"

const EmployeesContext = createContext<EmployeesContextType>({
    loading: false,
    employees: [],
    employeesById: {},
    addEmployee: defaultAddOne,
    getAllEmployees: defaultGetAll,
    getOneEmployee: defaultGetOne,
    removeOneEmployee: defaultRemoveOne,
    updateEmployee: defaultUpdateOne,
    updateRoles: (id, roleIds) => Promise.reject(id + roleIds.join(", ")),
    getRolesForEmployee: (id) => Promise.reject(id),
})

export const useEmployees = () => useContext(EmployeesContext)

const EmployeesProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const {
        dataById,
        data,
        removeOne,
        meta,
        loading,
        error,
        addOne,
        getOne,
        getAll,
        updateOne,
        setDataById,
    } = useStoreProvider<Employee>({ url: EMPLOYEES_PATH })
    const updateRoles: UpdateRoles = useCallback(
        (id, roles) => {
            return axios
                .patch<{}, ApiResponseWithData<Employee>>(`${EMPLOYEES_PATH}/${id}/roles`, { roles })
                .then((res) => {
                    const { data } = res
                    setDataById((prevState) => ({
                        ...prevState,
                        [data._id!]: data,
                    }))
                })
        },
        [setDataById],
    )

    const getRolesForEmployee: GetRolesForEmployee = useCallback((id: string) => {
        return axios.get<{}, ApiResponseWithData<Role[]>>(`${EMPLOYEES_PATH}/${id}/roles`).then((res) => {
            const { data } = res
            return data
        })
    }, [])

    return (
        <EmployeesContext.Provider
            value={{
                meta,
                error,
                loading,
                employeesById: dataById,
                employees: data,
                addEmployee: addOne,
                getAllEmployees: getAll,
                getOneEmployee: getOne,
                removeOneEmployee: removeOne,
                updateEmployee: updateOne,
                updateRoles,
                getRolesForEmployee,
            }}
        >
            {children}
        </EmployeesContext.Provider>
    )
}

export default EmployeesProvider
