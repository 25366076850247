import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import { makeStyles } from "@material-ui/core/styles"
import React, { CSSProperties, useState } from "react"
import { OnChange } from "../../Form"
import { firebaseStorage } from "../../../utils/firebase"
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField"

export type FileFieldProps = Omit<MuiTextFieldProps, "onChange" | "error" | "onBlur" | "type"> & {
    storagePath: string
    onChange: OnChange<string | null>
    error?: string
}

const progressClasses: { [k: string]: CSSProperties } = {}

for (let i = 1; i <= 100; i++) {
    progressClasses[`progress-${i}`] = { width: `${i}%`, borderColor: "blue" }
}

const useStyles = makeStyles(() => ({
    root: {
        cursor: "pointer",
    },
    nativeInput: {},
    ...progressClasses,
}))

let idCount = 0
const FileField = (props: FileFieldProps) => {
    const { name, onChange, error, label, required, variant, fullWidth, InputProps, storagePath } = props

    const [filePickerStatus, setFilePickerStatus] = useState<
        "initial" | "picker-open" | "canceled" | "uploading" | "uploaded"
    >("initial")
    const id = `TextField-${name}-${idCount++}`
    const [uploadProgress, setUploadProgress] = useState<number | null>(null)
    const classes = useStyles()

    const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = e.target.files
        if (files && files.length > 0) {
            const file: File = files[0]
            const uploadTask = firebaseStorage().ref(`${storagePath}/${file.name}`).put(file)
            setFilePickerStatus("uploading")
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    setUploadProgress(progress)
                },
                undefined,
                () => {
                    uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then((downloadURL: string) => {
                            onChange(downloadURL)
                        })
                        .then(() => setFilePickerStatus("uploaded"))
                },
            )
        }
    }

    return (
        <FormControl variant={variant} fullWidth={fullWidth} error={filePickerStatus === "canceled" && !!error}>
            <InputLabel shrink htmlFor={id}>
                {label}
            </InputLabel>
            <Input
                classes={{
                    input: classes.nativeInput,
                    ...(filePickerStatus === "uploading" && {
                        // @ts-ignore
                        underline: classes[`progress-${uploadProgress}`],
                    }),
                }}
                id={id}
                onClick={() => setFilePickerStatus("picker-open")}
                onFocus={() => {
                    if (filePickerStatus === "picker-open") {
                        setFilePickerStatus("canceled")
                    }
                }}
                className={classes.root}
                name={name}
                type={"file"}
                required={required}
                onChange={onFileUpload}
                {...InputProps}
            />
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

export default FileField
