import React from "react"
import { Typography } from "@material-ui/core"
import { formatDate } from "../../utils/date"
import { Employee } from "../../store/EmployeeStore"
import InformationGrid from "../../components/InformationGrid/InformationGrid"

export interface EmployeeInfoProps {
    employee?: Employee
}

const EmployeeInfo = (props: EmployeeInfoProps) => {
    const { employee } = props

    return (
        <InformationGrid.Container>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Navn
                </Typography>
                <Typography>{[employee?.given_name, employee?.family_name].join(" ")}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    E-post
                </Typography>
                <Typography>{employee?.email}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Telefonnummer
                </Typography>
                <Typography>{employee?.phone}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Stilling
                </Typography>
                <Typography>{employee?.position || "–"}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Bursdag
                </Typography>
                <Typography>{employee?.birthday ? formatDate(employee.birthday) : "–"}</Typography>
            </InformationGrid.Item>
        </InformationGrid.Container>
    )
}

export default EmployeeInfo
