import React, { createContext, ReactNode, useContext } from "react"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"
import { Person } from "./PersonStore"

export type Client = {
    _id: string
    name: string
    contactPerson?: Person
    phone?: string
    mail?: string
    logo?: string
}

export interface ClientsContextType extends BaseContext {
    clients: Client[]
    clientsById: { [id in string]: Client }
    addClient: AddOne<Client>
    updateClient: UpdateOne<Client>
    getOneClient: GetOne
    getAllClients: GetAll
    removeOneClient: RemoveOne
}

export const CLIENTS_PATH = "/clients"

const ClientsContext = createContext<ClientsContextType>({
    loading: false,
    clients: [],
    clientsById: {},
    addClient: defaultAddOne,
    getAllClients: defaultGetAll,
    getOneClient: defaultGetOne,
    removeOneClient: defaultRemoveOne,
    updateClient: defaultUpdateOne,
})

export const useClients = (): ClientsContextType => useContext(ClientsContext)

const ClientsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { dataById, data, removeOne, meta, loading, error, addOne, getOne, getAll, updateOne } = useStoreProvider<
        Client
    >({ url: CLIENTS_PATH })

    return (
        <ClientsContext.Provider
            value={{
                meta,
                error,
                loading,
                clientsById: dataById,
                clients: data,
                addClient: addOne,
                getAllClients: getAll,
                getOneClient: getOne,
                removeOneClient: removeOne,
                updateClient: updateOne,
            }}
        >
            {children}
        </ClientsContext.Provider>
    )
}

export default ClientsProvider
