import React from "react"
import Router from "../../utils/Router"

const LandingComponent = () => {
    return (
        <div>
            <Router layout={"landing"} />
        </div>
    )
}
export default LandingComponent
