import React, { createContext, ReactNode, useContext } from "react"
import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"
import { Client } from "./ClientStore"

export type Project = {
    _id: string
    name: string
    description?: string
    projectType?: string
    client: Client
}

export interface ProjectsContextType extends BaseContext {
    projects: Project[]
    projectsById: { [id in string]?: Project }
    addProject: AddOne<Project>
    updateProject: UpdateOne<Project>
    getOneProject: GetOne
    getAllProjects: GetAll
    removeOneProject: RemoveOne
}

export const PROJECTS_PATH = "/projects"

const ProjectsContext = createContext<ProjectsContextType>({
    loading: false,
    projects: [],
    projectsById: {},
    addProject: defaultAddOne,
    getAllProjects: defaultGetAll,
    getOneProject: defaultGetOne,
    removeOneProject: defaultRemoveOne,
    updateProject: defaultUpdateOne,
})

export const useProjects = (): ProjectsContextType => useContext(ProjectsContext)

const ProjectsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { dataById, data, removeOne, meta, loading, error, addOne, getOne, getAll, updateOne } = useStoreProvider<
        Project
    >({ url: PROJECTS_PATH })

    return (
        <ProjectsContext.Provider
            value={{
                meta,
                error,
                loading,
                projectsById: dataById,
                projects: data,
                addProject: addOne,
                getAllProjects: getAll,
                getOneProject: getOne,
                removeOneProject: removeOne,
                updateProject: updateOne,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    )
}

export default ProjectsProvider
