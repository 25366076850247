import React, { useRef, useState } from "react"
import { OnChange } from "../../Form"
import {
    ContentBlock,
    DraftHandleValue,
    Editor,
    DraftEditorCommand,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
} from "draft-js"
import BlockStyleControls from "./Controls/BlockStyleControls"
import InlineStyleControls from "./Controls/InlineStyleControls"
import "./RichTextField.css"
import { makeStyles, Theme } from "@material-ui/core/styles"
import cn from "classnames"
import { FormHelperText, InputLabel } from "@material-ui/core"
import { stateToHTML } from "draft-js-export-html"
import { stateFromHTML } from "draft-js-import-html"

export type TextFieldProps = {
    onChange: OnChange<string>
    error?: string
    value: string | undefined
    label?: string
}

const styleMap = {
    CODE: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    editorWrapper: {
        marginTop: theme.spacing(2),
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.grey.A400}`,
        padding: theme.spacing(2),
        width: "100%",
    },
    editorContainer: {
        borderTop: `1px solid ${theme.palette.grey["50"]}`,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        "&.placeholderHidden .public-DraftEditorPlaceholder-root": {
            display: "none",
        },
        "& .public-DraftStyleDefault-pre": {
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            padding: theme.spacing(3),
            fontFamily: "'Inconsolata', 'Menlo', 'Consolas', monospace",
        },

        "& .RichEditor-blockquote": {
            borderLeft: `5px solid ${theme.palette.grey["400"]}`,
            color: theme.palette.grey["500"],
            padding: "10px 20px",
            margin: "16px 0",
        },

        "& .public-DraftEditor-content": {
            minHeight: 200,
        },
    },
}))

const RichTextField = (props: TextFieldProps) => {
    const { value, onChange, error, label } = props

    const classes = useStyles()

    const editor = useRef<Editor>(null)

    const [editorState, setEditorState] = useState<EditorState>(() =>
        value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty(),
    )
    const contentState = editorState.getCurrentContent()

    const toggleBlockType = (blockType: string) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }

    const toggleInlineStyle = (inlineStyle: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }

    const getBlockStyle = (block: ContentBlock): string => {
        switch (block.getType()) {
            case "blockquote":
                return "RichEditor-blockquote"
            default:
                return ""
        }
    }

    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState)
            return "handled"
        }
        return "not-handled"
    }

    const mapKeyToEditorCommand = (e: React.KeyboardEvent<{}>): DraftEditorCommand | null => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, editorState, 4)
            if (newEditorState !== editorState) {
                setEditorState(newEditorState)
            }
            return null
        }
        return getDefaultKeyBinding(e)
    }

    return (
        <div className={classes.root}>
            <InputLabel>{label}</InputLabel>

            <div className={classes.editorWrapper}>
                <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
                <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
                <div
                    className={cn(classes.editorContainer, {
                        placeholderHidden: contentState.getBlockMap().first().getType() !== "unstyled",
                    })}
                    onClick={() => editor.current?.focus()}
                >
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={mapKeyToEditorCommand}
                        onChange={(_editorState) => {
                            setEditorState(_editorState)
                            onChange(stateToHTML(_editorState.getCurrentContent()))
                        }}
                        placeholder="Tell a story..."
                        ref={editor}
                        spellCheck={true}
                    />
                </div>
            </div>
            <FormHelperText error={!!error}>{error}</FormHelperText>
        </div>
    )
}

export default RichTextField
