import React, { ReactNode } from "react"
import { Grid, makeStyles } from "@material-ui/core"

export interface InformationGridItemProps {
    children: ReactNode
}

const useStyles = makeStyles(() => ({
    infoContainer: {
        display: "flex",
        flexDirection: "column",
    },
}))

const InformationGridItem = (props: InformationGridItemProps) => {
    const { children } = props

    const classes = useStyles()

    return (
        <Grid item className={classes.infoContainer} xs={12} md={6}>
            {children}
        </Grid>
    )
}

export default InformationGridItem
