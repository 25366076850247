import React, { ReactNode } from "react"
import { AppBar, Theme, Toolbar, makeStyles, AppBarProps } from "@material-ui/core"
import cn from "classnames"

export interface HeaderProps extends AppBarProps {
    className?: string
    children?: ReactNode | ReactNode[]
}

export const headerHeight = 64

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: headerHeight,
    },
    content: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: theme.spacing(1),
    },
}))

const HeaderComponent = (props: HeaderProps) => {
    const classes = useStyles()
    const { children, className, ...rest } = props

    return (
        <AppBar position={"fixed"} className={cn(classes.root)} {...rest}>
            <Toolbar className={cn(classes.content, className)}>{children}</Toolbar>
        </AppBar>
    )
}

export default HeaderComponent
