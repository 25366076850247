import React, { Dispatch, SetStateAction, useCallback, useState } from "react"
import { ApiError, ApiMeta, ApiResponseWithData, ApiResponse } from "../api/types"
import axios from "axios"
import NotImplementedException from "../Exceptions/NotImplementedException"

export interface UseStoreProviderProps {
    url: string
}
export interface UseStoreProviderReturnType<DataType> {
    getAll: GetAll
    getOne: GetOne
    addOne: AddOne<DataType>
    updateOne: UpdateOne<DataType>
    removeOne: RemoveOne
    meta?: ApiMeta
    error?: ApiError
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    data: DataType[]
    dataById: { [id: string]: DataType }
    setDataById: Dispatch<SetStateAction<{ [id: string]: DataType }>>
}

export interface GetAllParams {
    page?: number
    count?: number
}

export type GetAll<Params = {}> = (params?: GetAllParams & Params) => void
export interface UpdateOne<D extends {}> {
    (id: string, updatedDocument: Partial<D>): Promise<void>
    loading?: boolean
}
export type AddOne<D extends {}> = (document: Omit<D, "_id">) => Promise<void>
export type GetOne = (id: string) => void
export type RemoveOne = (id: string) => Promise<void>

export interface BaseContext {
    meta?: ApiMeta
    error?: ApiError
    loading: boolean
}

export const useStoreProvider = <DataType extends { _id?: string }>(
    props: UseStoreProviderProps,
): UseStoreProviderReturnType<DataType> => {
    const { url } = props
    const [loading, setLoading] = React.useState<boolean>(false)
    const [storeDataById, setStoreDataById] = React.useState<{
        [id: string]: DataType
    }>({})

    const [storeMeta, setStoreMeta] = React.useState<ApiMeta | undefined>()
    const [storeError, setStoreError] = useState<ApiError | undefined>()

    const getAll: GetAll = useCallback(
        (params?) => {
            setLoading(true)
            axios
                .get<{}, ApiResponseWithData<DataType[]>>(url, { params })
                .then((res) => {
                    const { data, meta } = res
                    setStoreMeta((oldMeta) => ({ ...oldMeta, ...meta }))
                    setStoreDataById(
                        data.reduce((accumulator: { [id: string]: DataType }, currVal: DataType) => {
                            return { ...accumulator, [currVal._id!]: currVal }
                        }, {}),
                    )
                })
                .then(() => {
                    setLoading(false)
                })
        },
        [url],
    )

    const updateOne: UpdateOne<DataType> = useCallback(
        (id, updatedDocument) => {
            setLoading(true)
            return axios.patch<{}, ApiResponseWithData<DataType>>(`${url}/${id}`, updatedDocument).then((res) => {
                const { data, meta } = res
                setStoreMeta((oldMeta) => ({ ...oldMeta, ...meta }))
                setStoreDataById((prevState) => ({
                    ...prevState,
                    [id]: data,
                }))
                setLoading(false)
            })
        },
        [url],
    )

    const getOne: GetOne = useCallback(
        (id) => {
            axios.get<{}, ApiResponseWithData<DataType>>(`${url}/${id}`).then((res) => {
                const { data, meta } = res
                setStoreMeta((oldMeta) => ({ ...oldMeta, ...meta }))
                setStoreDataById((prevState) => ({
                    ...prevState,
                    [data._id!]: data,
                }))
                setLoading(false)
            })
        },
        [url],
    )

    const removeOne: RemoveOne = useCallback(
        (id) => {
            setLoading(true)
            return axios.delete<{}, ApiResponse>(`${url}/${id}`).then((res) => {
                const { meta } = res
                setStoreMeta((oldMeta) => ({ ...oldMeta, ...meta }))
                setStoreDataById((prevState) => {
                    delete prevState[id]
                    return { ...prevState }
                })
                setLoading(false)
            })
        },
        [url],
    )

    const addOne = useCallback(
        (document: Omit<DataType, "_id">) => {
            setLoading(true)
            return axios
                .post<{}, ApiResponseWithData<DataType>>(`${url}`, document)
                .then((res) => {
                    const { data, meta } = res
                    setStoreMeta((oldMeta) => ({ ...oldMeta, ...meta }))
                    setStoreDataById((prevState) => ({
                        [data._id!]: data,
                        ...prevState,
                    }))

                    setLoading(false)
                })
                .catch((res) => {
                    setStoreError(res.error)
                    throw res.error.fields
                })
        },
        [url],
    )

    const getDataAsArray = useCallback(() => {
        return Object.values(storeDataById)
    }, [storeDataById])

    return {
        getAll,
        getOne,
        addOne,
        error: storeError,
        loading,
        setLoading,
        meta: storeMeta,
        removeOne,
        updateOne,
        data: getDataAsArray(),
        dataById: storeDataById,
        setDataById: setStoreDataById,
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultAddOne: AddOne<Record<string, unknown>> = (document) => {
    throw new NotImplementedException(defaultAddOne.name)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultGetAll: GetAll = (params) => {
    throw new NotImplementedException(defaultGetAll.name)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultGetOne: GetOne = () => {
    throw new NotImplementedException(defaultGetOne.name)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultRemoveOne: RemoveOne = (id) => {
    throw new NotImplementedException(defaultRemoveOne.name)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultUpdateOne: UpdateOne<Record<string, unknown>> = (id, updatedDocument) => {
    throw new NotImplementedException(defaultUpdateOne.name)
}
