import { Template, TemplateVariant, useTemplates } from "../store/TemplateStore"
import { Grid, Typography } from "@material-ui/core"
import TextField from "../components/Fields/TextField"
import SubmitButton from "../components/Fields/SubmitButton"
import Form from "../components/Form"
import React, { useEffect } from "react"
import "draft-js/dist/Draft.css"
import RichTextField from "../components/Fields/RichTextField"
import AutoComplete from "../components/Fields/AutoComplete"

export interface TemplateFormProps {
    onSubmitted?: () => void
    initialValues?: Template
}

const TemplateForm = (props: TemplateFormProps) => {
    const { onSubmitted, initialValues } = props
    const {
        addTemplate,
        updateTemplate,
        error,
        templateVariants,
        templateVariantsByKey,
        getTemplateVariants,
        loading,
    } = useTemplates()

    const onSubmit = (template: Template) => {
        if (!!template._id) {
            return updateTemplate(template._id, template).then(() => {
                onSubmitted?.()
            })
        }
        return addTemplate(template).then(() => {
            onSubmitted?.()
        })
    }

    useEffect(() => {
        getTemplateVariants()
    }, [getTemplateVariants])

    return loading ? null : (
        <Form<Template> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            onChange={onChangeFor<Template["name"]>("name")}
                            value={values["name"]}
                            error={errors["name"]}
                            fullWidth
                            label={"Navn"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoComplete<TemplateVariant>
                            fullWidth
                            label={"Variant"}
                            getOptionSelected={(option1, option2) => option1.key === option2.key}
                            getOptionLabel={(variant) => variant.name}
                            options={templateVariants}
                            onChange={(variant) => onChangeFor<Template["variant"]>("variant")(variant?.key ?? null)}
                            value={values["variant"] ? templateVariantsByKey[values["variant"]] : null}
                            error={errors["variant"]}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} style={{ minHeight: 200 }}>
                        <RichTextField
                            label={"Innhold"}
                            onChange={onChangeFor<Template["content"]>("content")}
                            value={values.content}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", marginTop: 12 }}>
                        <Typography color={"error"}>{error?.message}</Typography>
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default TemplateForm
