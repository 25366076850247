import { Client, useClients } from "../store/ClientStore"
import { Grid, Typography } from "@material-ui/core"
import TextField from "../components/Fields/TextField"
import SubmitButton from "../components/Fields/SubmitButton"
import Form from "../components/Form"
import React, { useEffect } from "react"
import PhoneField from "../components/Fields/PhoneField"
import AutoComplete from "../components/Fields/AutoComplete"
import { Check as SelectedIcon } from "@material-ui/icons"
import { Person, usePersons } from "../store/PersonStore"
import FileField from "../components/Fields/FileField"
import { AutocompleteRenderOptionState } from "@material-ui/lab"

export interface ClientFormProps {
    onSubmitted?: () => void
    initialValues?: Partial<Client>
}

const ClientForm = (props: ClientFormProps) => {
    const { onSubmitted, initialValues } = props
    const { addClient, updateClient, error } = useClients()
    const { persons, getAllPersons } = usePersons()

    useEffect(() => {
        getAllPersons()
    }, [getAllPersons])

    const onSubmit = (client: Client) => {
        if (!!client._id) {
            return updateClient(client._id, client).then(() => {
                onSubmitted?.()
            })
        }
        return addClient(client).then(() => {
            onSubmitted?.()
        })
    }

    return (
        <Form<Client> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            onChange={onChangeFor<Client["name"]>("name")}
                            value={values["name"]}
                            error={errors["name"]}
                            fullWidth
                            label={"Navn"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AutoComplete<Person>
                            label={"Kontaktperson"}
                            fullWidth
                            onChange={onChangeFor<Person>("contactPerson")}
                            error={errors.contactPerson}
                            options={persons}
                            getOptionSelected={(option: Person, value: Person) => option._id === value._id}
                            getOptionLabel={(option: Person) => [option.first_name, option.last_name].join(" ")}
                            renderOption={(option: Person, state: AutocompleteRenderOptionState) => {
                                return (
                                    <>
                                        {[option.first_name, option.last_name].join(" ")}
                                        {state.selected && <SelectedIcon style={{ marginLeft: 12 }} />}
                                    </>
                                )
                            }}
                            noOptionsText={`Søket ga ingen treff`}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneField
                            onChange={onChangeFor<string>("phone")}
                            value={values.phone}
                            error={errors.phone}
                            fullWidth
                            name={"phone"}
                            label={"Telefon"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("mail")}
                            error={errors["mail"]}
                            value={values["mail"]}
                            type={"email"}
                            fullWidth
                            label={"E-post"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FileField
                            onChange={onChangeFor<string>("logo")}
                            value={values.logo}
                            error={errors.logo}
                            storagePath={"/clients/img/logos"}
                            name={"logo"}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", marginTop: 12 }}>
                        <Typography color={"error"}>{error?.message}</Typography>
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default ClientForm
