import React, { useCallback, useState } from "react"
import routes from "./routes"

// Layouts
import AdminLayout from "./layouts/Admin"
import LandingLayout from "./layouts/Landing"

// Contexts
import AxiosProvider from "./utils/AxiosProvider/AxiosProvider"
import Store from "./store"
import PickerProvider from "./utils/PickerProvider"
import Auth0Provider from "./utils/Auth0Provider"
import { AuthAppState } from "./utils/Auth0Provider"

// React router
import { Routes, Route, BrowserRouter } from "react-router-dom"

// Styling
import { CssBaseline, createMuiTheme, useMediaQuery, ThemeProvider } from "@material-ui/core"
import theme from "./styles/themes/odecon"
import { useGlobalStyles } from "./styles/themes/globalStyles"

const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

    const [{ REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE }] = useState(process.env)
    useGlobalStyles()

    const onRedirectCallback = useCallback((appState: AuthAppState) => {
        window.history.replaceState(
            {},
            document.title,
            appState?.targetUrl ? appState.targetUrl : window.location.pathname,
        )
    }, [])

    return (
        <BrowserRouter>
            <Auth0Provider
                onRedirectCallback={onRedirectCallback}
                domain={REACT_APP_AUTH0_DOMAIN || ""}
                client_id={REACT_APP_AUTH0_CLIENT_ID || ""}
                redirect_uri={`${window.location.origin}/admin/dashboard`}
                audience={REACT_APP_AUTH0_AUDIENCE}
                useFirebase={true}
            >
                <AxiosProvider>
                    <ThemeProvider
                        theme={createMuiTheme({
                            ...theme,
                            palette: {
                                ...theme.palette,
                                type: prefersDarkMode ? "dark" : "light",
                            },
                        })}
                    >
                        <CssBaseline>
                            <Store>
                                <PickerProvider>
                                    <div id={"App"}>
                                        <Routes>
                                            <Route path={routes.admin.path + "/*"} element={<AdminLayout />} />
                                            <Route path={routes.landing.path + "/*"} element={<LandingLayout />} />
                                        </Routes>
                                    </div>
                                </PickerProvider>
                            </Store>
                        </CssBaseline>
                    </ThemeProvider>
                </AxiosProvider>
            </Auth0Provider>
        </BrowserRouter>
    )
}

export default App
