import { Fab, FabProps, makeStyles } from "@material-ui/core"
import { isEmpty } from "lodash"
import React from "react"
import { FormErrors } from "../../Form"
import cn from "classnames"

export interface SubmitButtonProps extends Omit<FabProps, "type"> {
    children: React.ReactNode
    float?: "right" | "left"
    errors: FormErrors
}

const useStyles = makeStyles(() => ({
    root: {},
    floatLeft: {
        marginRight: "auto",
    },
    floatRight: {
        marginLeft: "auto",
    },
}))

const SubmitButton = (props: SubmitButtonProps) => {
    const { float, className, disabled, errors, ...rest } = props
    const classes = useStyles()

    return (
        <Fab
            color={"primary"}
            variant={"extended"}
            className={cn(classes.root, className, {
                [classes.floatLeft]: float === "left",
                [classes.floatRight]: float === "right",
            })}
            {...rest}
            type={"submit"}
            disabled={disabled || !isEmpty(errors)}
        />
    )
}

export default SubmitButton
