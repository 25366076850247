import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core"
import { red } from "@material-ui/core/colors"

export const defaultTheme: Theme = createMuiTheme()

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: "#FFA800",
        },
        secondary: {
            main: "#998bd6",
        },
        error: red,
    },
    typography: {
        fontFamily: ["Roboto Condensed", "Roboto", "Helvetica", "sans-serif"].join(", "),
        body1: {
            fontSize: "1.1rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "1rem",
            },
        },
        h1: {
            fontSize: 36,
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: 32,
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: 28,
            },
        },
        h2: {
            fontSize: 32,
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: 28,
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: 24,
            },
        },
        h3: {
            fontSize: 28,
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: 24,
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: 20,
            },
        },
        h5: {
            fontSize: 24,
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: 20,
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: 16,
            },
        },
        subtitle1: {
            opacity: 0.7,
            textTransform: "uppercase",
        },
        subtitle2: {
            opacity: 0.7,
            textTransform: "uppercase",
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                borderRadius: 200,
            },
        },
        MuiCard: {
            root: {
                boxShadow: defaultTheme.shadows[4],
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: "#FFA800",
            },
        },
        MuiGrid: {
            item: {
                display: "flex",
            },
        },
        MuiTableCell: {
            root: {
                padding: 10,
            },
        },
    },
}

export default theme
