import { Project } from "../../store/ProjectStore"
import { Typography } from "@material-ui/core"
import React from "react"
import InformationGrid from "../../components/InformationGrid"
import { Link } from "react-router-dom"

export interface ProjectInfoProps {
    project?: Project
}

const ProjectInfo = (props: ProjectInfoProps) => {
    const { project } = props

    return (
        <InformationGrid.Container>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Navn
                </Typography>
                <Typography>{project?.name}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Prosjekttype
                </Typography>
                <Typography>{project?.projectType}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Klient
                </Typography>
                <Link to={`../../clients/${project?.client._id}`} state={{ redirectTo: window.location.pathname }}>
                    <Typography>{project?.client.name}</Typography>
                </Link>
            </InformationGrid.Item>
        </InformationGrid.Container>
    )
}

export default ProjectInfo
