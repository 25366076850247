import React, { ReactNode } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import cn from "classnames"

export interface TabPanelProps {
    children?: ReactNode | ReactNode[]
    value: number
    index: number
    className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: `${theme.spacing(4)}px 0`,
        marginTop: theme.spacing(2),
    },
}))

const TabPanel = (props: TabPanelProps) => {
    const { value, index, className, ...rest } = props
    const classes = useStyles()
    return <div className={cn(classes.root, className)} role={"tabpanel"} hidden={value !== index} {...rest} />
}

export default TabPanel
