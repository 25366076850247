import DateFnsUtils from "@date-io/date-fns"
import * as dateFns from "date-fns"
import _ from "lodash"

class PickerUtils extends DateFnsUtils {
    public static getDatePickerHeaderText(date: Date) {
        return _.upperFirst(dateFns.format(date, "d. MMM YYYY"))
    }

    public static getCalendarHeaderText(date: Date): string {
        return dateFns.format(date, "MMMM YYYY")
    }
}

export default PickerUtils
