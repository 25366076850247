import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import View from "../../components/View"
import { useProjects } from "../../store/ProjectStore"
import ViewContent from "../../components/View/ViewContent"
import { Button, CircularProgress, Paper, Tab, Tabs } from "@material-ui/core"
import { NavigateBefore as BackIcon } from "@material-ui/icons"
import TabPanel from "../../components/TabPanel"
import BasicInfoHeader from "../../components/BasicInfoHeader"
import ProjectInfo from "./ProjectInfo"

const Project = () => {
    const { projectsById, getOneProject, loading } = useProjects()
    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState<number>(0)

    const { id } = useParams()
    const project = projectsById[id]

    useEffect(() => {
        getOneProject(id)
    }, [getOneProject, id])

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            <View>
                <Button onClick={() => navigate("..")}>
                    <BackIcon fontSize={"small"} style={{ marginRight: 12 }} />
                    Tilbake til prosjekter
                </Button>
                <BasicInfoHeader title={project?.name} subtitle={project?.projectType} />
                <ViewContent>
                    <Paper style={{ padding: "0 24px 24px 24px" }}>
                        <Tabs
                            indicatorColor={"primary"}
                            value={tabValue}
                            onChange={(e, newTabValue) => setTabValue(newTabValue)}
                            aria-label={"Navigation tabs"}
                        >
                            <Tab label={"Info"} />
                            <Tab label={"Historie"} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <ProjectInfo project={project} />
                        </TabPanel>
                    </Paper>
                </ViewContent>
            </View>
        </>
    )
}

export default Project
