import React, { ReactNode } from "react"
import { Grid } from "@material-ui/core"

export interface InformationGridContainerProps {
    children: ReactNode
}

const InformationGridContainer = (props: InformationGridContainerProps) => {
    const { children } = props
    return (
        <Grid container spacing={4}>
            {children}
        </Grid>
    )
}

export default InformationGridContainer
