import { Project, useProjects } from "../store/ProjectStore"
import { Grid, Typography } from "@material-ui/core"
import TextField from "../components/Fields/TextField"
import SubmitButton from "../components/Fields/SubmitButton"
import Form from "../components/Form"
import React, { useEffect } from "react"
import { AutocompleteRenderOptionState } from "@material-ui/lab"
import { Check as SelectedIcon } from "@material-ui/icons"
import AutoComplete from "../components/Fields/AutoComplete"
import { Client, useClients } from "../store/ClientStore"

export interface ProjectFormProps {
    onSubmitted?: () => void
    initialValues?: Partial<Project>
}

const ProjectForm = (props: ProjectFormProps) => {
    const { onSubmitted, initialValues } = props
    const { addProject, updateProject, error } = useProjects()
    const { clients, getAllClients } = useClients()

    useEffect(() => {
        getAllClients()
    }, [getAllClients])

    const onSubmit = (project: Project) => {
        if (!!project._id) {
            return updateProject(project._id, project).then(() => {
                onSubmitted?.()
            })
        }
        return addProject(project).then(() => {
            onSubmitted?.()
        })
    }

    return (
        <Form<Project> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            onChange={onChangeFor<Project["name"]>("name")}
                            value={values["name"]}
                            error={errors["name"]}
                            fullWidth
                            label={"Navn"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            onChange={onChangeFor<Project["projectType"]>("projectType")}
                            value={values["projectType"]}
                            error={errors["projectType"]}
                            fullWidth
                            label={"Prosjekttype"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AutoComplete<Client>
                            label={"Klient"}
                            fullWidth
                            required
                            error={errors.client}
                            onChange={onChangeFor<Client>("client")}
                            options={clients}
                            getOptionSelected={(option: Client, value: Client) => option._id === value._id}
                            getOptionLabel={(option: Client) => option.name}
                            renderOption={(option: Client, state: AutocompleteRenderOptionState) => {
                                return (
                                    <>
                                        {option.name}
                                        {state.selected && <SelectedIcon style={{ marginLeft: 12 }} />}
                                    </>
                                )
                            }}
                            noOptionsText={`Søket ga ingen treff`}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<Project["description"]>("description")}
                            value={values["description"]}
                            error={errors["description"]}
                            fullWidth
                            label={"Beskrivelse"}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", marginTop: 12 }}>
                        <Typography color={"error"}>{error?.message}</Typography>
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default ProjectForm
