import View from "../../components/View"
import React from "react"
import ViewHeader from "../../components/View/ViewHeader"
import ViewContent from "../../components/View/ViewContent"

const Settings = () => {
    return (
        <View>
            <ViewHeader title={"Settings"} />
            <ViewContent>Halla balla</ViewContent>
        </View>
    )
}

export default Settings
