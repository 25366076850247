import React from "react"
import StyleButton from "../Buttons/StyleButton"
import { ControlsComponentProps } from "./types"
import { makeStyles, Theme } from "@material-ui/core/styles"

const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Code Block", style: "code-block" },
]

export type BlockStyleControlsProps = ControlsComponentProps

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(1),
        userSelect: "none",
    },
}))

const BlockStyleControls = (props: BlockStyleControlsProps) => {
    const { editorState, onToggle } = props

    const classes = useStyles()

    const selection = editorState.getSelection()
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

    return (
        <div className={classes.root}>
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </div>
    )
}

export default BlockStyleControls
