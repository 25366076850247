import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import View from "../../components/View"
import { useClients } from "../../store/ClientStore"
import ViewContent from "../../components/View/ViewContent"
import { Button, CircularProgress, IconButton, Link, Paper, Tab, Tabs } from "@material-ui/core"
import { NavigateBefore as BackIcon } from "@material-ui/icons"
import TabPanel from "../../components/TabPanel"
import BasicInfoHeader from "../../components/BasicInfoHeader"
import { Phone as PhoneIcon, Mail as MailIcon } from "@material-ui/icons"
import { useIsSizeDown } from "../../utils/hooks"
import ClientInfo from "./ClientInfo"
import { Link as RouterLink } from "react-router-dom"

const Client = () => {
    const { clientsById, getOneClient, loading } = useClients()
    const locationState = useLocation().state as { redirectTo?: string }
    const [tabValue, setTabValue] = useState<number>(0)

    const { id } = useParams()
    const client = clientsById[id]

    const isSmall = useIsSizeDown("sm")

    useEffect(() => {
        getOneClient(id)
    }, [getOneClient, id])

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            <View>
                <Button component={RouterLink} to={locationState?.redirectTo ?? ".."}>
                    <BackIcon fontSize={"small"} style={{ marginRight: 12 }} />
                    Tilbake
                </Button>
                <BasicInfoHeader
                    title={client?.name}
                    actions={
                        <div style={{ marginTop: -8 }}>
                            {client?.phone && (
                                <Link target="_blank" component={IconButton} href={"tel:" + client.phone}>
                                    <PhoneIcon color={"secondary"} fontSize={!isSmall ? "large" : "default"} />
                                </Link>
                            )}
                            {client?.mail && (
                                <Link target="_blank" component={IconButton} href={"mailto:" + client.mail}>
                                    <MailIcon color={"secondary"} fontSize={!isSmall ? "large" : "default"} />
                                </Link>
                            )}
                        </div>
                    }
                    image={{ src: client?.logo, alt: client?.name }}
                />
                <ViewContent>
                    <Paper style={{ padding: "0 24px 24px 24px" }}>
                        <Tabs
                            indicatorColor={"primary"}
                            value={tabValue}
                            onChange={(e, newTabValue) => setTabValue(newTabValue)}
                            aria-label={"Navigation tabs"}
                        >
                            <Tab label={"Info"} />
                            <Tab label={"Historie"} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <ClientInfo client={client} />
                        </TabPanel>
                    </Paper>
                </ViewContent>
            </View>
        </>
    )
}

export default Client
