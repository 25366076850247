import React, { ReactNode, useEffect } from "react"
import axios from "axios"
import { useAuth0 } from "../Auth0Provider"

export interface AxiosProviderProps {
    children: ReactNode | ReactNode[]
}

axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.response.use(
    (res) => {
        return res.data
    },
    (err) => {
        if (err.response === undefined) {
            return Promise.reject({
                messages: [
                    {
                        type: "error",
                        message: "Network error",
                        description: "Unable to connect to server",
                    },
                ],
            })
        }
        const data = err.response.data
        return Promise.reject({ ...data })
    },
)

const AxiosProvider = (props: AxiosProviderProps) => {
    const { children } = props
    const { getTokenSilently } = useAuth0()

    useEffect(() => {
        getTokenSilently().then((token) => {
            axios.interceptors.request.use((requestConfig) => {
                requestConfig.headers["Authorization"] = `Bearer ${token}`
                return requestConfig
            })
        })
    }, [getTokenSilently])
    return <>{children}</>
}

export default AxiosProvider
