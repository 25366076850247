import { Client } from "../../store/ClientStore"
import { Typography } from "@material-ui/core"
import React from "react"
import InformationGrid from "../../components/InformationGrid"
import { Link } from "react-router-dom"

export interface ClientInfoProps {
    client?: Client
}

const ClientInfo = (props: ClientInfoProps) => {
    const { client } = props

    return (
        <InformationGrid.Container>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Navn
                </Typography>
                <Typography>{client?.name}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    E-post
                </Typography>
                <Typography>{client?.mail}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Telefonnummer
                </Typography>
                <Typography>{client?.phone}</Typography>
            </InformationGrid.Item>
            <InformationGrid.Item>
                <Typography gutterBottom variant={"subtitle2"}>
                    Kontaktperson
                </Typography>
                <Typography>
                    <Link
                        to={`../../persons/${client?.contactPerson?._id}`}
                        state={{ redirectTo: window.location.pathname }}
                    >
                        <Typography>
                            {[client?.contactPerson?.first_name, client?.contactPerson?.last_name].join(" ")}
                        </Typography>
                    </Link>
                </Typography>
            </InformationGrid.Item>
        </InformationGrid.Container>
    )
}

export default ClientInfo
