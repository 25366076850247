import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import cn from "classnames"

export interface StyleButtonProps {
    active?: boolean
    onToggle: (style: string) => void
    style: string
    label: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.text.disabled,
        cursor: "pointer",
        marginRight: theme.spacing(2),
        padding: "2px 0",
        display: "inline-block",
        "&.active": {
            color: theme.palette.info.main,
        },
    },
}))

const StyleButton = (props: StyleButtonProps) => {
    const { active, style, onToggle, label } = props
    const classes = useStyles()

    return (
        <span
            className={cn(classes.root, { active })}
            onMouseDown={(e) => {
                e.preventDefault()
                onToggle(style)
            }}
        >
            {label}
        </span>
    )
}

export default StyleButton
