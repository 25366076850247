import React, { createContext, ReactNode, useCallback, useContext, useState } from "react"

import {
    AddOne,
    BaseContext,
    defaultAddOne,
    defaultGetAll,
    defaultGetOne,
    defaultRemoveOne,
    defaultUpdateOne,
    GetAll,
    GetOne,
    RemoveOne,
    UpdateOne,
} from "../utils/hooks/useStoreProvider"
import { useStoreProvider } from "../utils/hooks"
import axios from "axios"
import { ApiResponseWithData } from "../utils/api/types"

export interface Template {
    _id: string
    name: string
    content: string
    variant: string
}

export interface TemplateVariant {
    key: string
    name: string
    placeholders: string[]
}

export interface TemplatesContextType extends BaseContext {
    templates: Template[]
    templatesById: { [id in string]: Template }
    addTemplate: AddOne<Template>
    updateTemplate: UpdateOne<Template>
    getOneTemplate: GetOne
    getAllTemplates: GetAll
    removeOneTemplate: RemoveOne
    templateVariants: TemplateVariant[]
    templateVariantsByKey: { [key in string]: TemplateVariant }
    getTemplateVariants: GetAll
}

export const TEMPLATES_PATH = "/templates"
export const TEMPLATE_VARIANTS_PATH = "/templates/variants"

const TemplatesContext = createContext<TemplatesContextType>({
    loading: false,
    templates: [],
    templatesById: {},
    addTemplate: defaultAddOne,
    getAllTemplates: defaultGetAll,
    getOneTemplate: defaultGetOne,
    removeOneTemplate: defaultRemoveOne,
    updateTemplate: defaultUpdateOne,
    templateVariants: [],
    templateVariantsByKey: {},
    getTemplateVariants: defaultGetAll,
})

export const useTemplates = (): TemplatesContextType => useContext(TemplatesContext)

const TemplatesProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const {
        dataById,
        data,
        removeOne,
        meta,
        loading,
        error,
        addOne,
        getOne,
        getAll,
        updateOne,
        setLoading,
    } = useStoreProvider<Template>({ url: TEMPLATES_PATH })

    const [templateVariantsByKey, setTemplateVariantsByKey] = useState<{ [key in string]: TemplateVariant }>({})

    const getTemplateVariantsAsArray = useCallback(() => {
        return Object.values(templateVariantsByKey)
    }, [templateVariantsByKey])

    const getTemplateVariants: GetAll = useCallback(() => {
        setLoading(true)
        axios
            .get<{}, ApiResponseWithData<TemplateVariant[]>>(TEMPLATE_VARIANTS_PATH)
            .then((res) => {
                const { data } = res
                setTemplateVariantsByKey(
                    data.reduce((accumulator: { [key: string]: TemplateVariant }, currVal: TemplateVariant) => {
                        return { ...accumulator, [currVal.key!]: currVal }
                    }, {}),
                )
            })
            .then(() => {
                setLoading(false)
            })
    }, [TEMPLATE_VARIANTS_PATH])

    return (
        <TemplatesContext.Provider
            value={{
                meta,
                error,
                loading,
                templatesById: dataById,
                templates: data,
                addTemplate: addOne,
                getAllTemplates: getAll,
                getOneTemplate: getOne,
                removeOneTemplate: removeOne,
                updateTemplate: updateOne,
                templateVariants: getTemplateVariantsAsArray(),
                templateVariantsByKey,
                getTemplateVariants,
            }}
        >
            {children}
        </TemplatesContext.Provider>
    )
}

export default TemplatesProvider
