import { Employee, useEmployees } from "../store/EmployeeStore"
import { Grid, Typography } from "@material-ui/core"
import TextField from "../components/Fields/TextField"
import SubmitButton from "../components/Fields/SubmitButton"
import Form from "../components/Form"
import React from "react"
import PhoneField from "../components/Fields/PhoneField"
import DatePicker from "../components/Fields/DatePicker/DatePickerField"

export type EmployeeFormFields = "email" | "given_name" | "family_name" | "phone" | "position" | "birthday"

export interface EmployeeFormProps {
    onSubmitted?: () => void
    id?: string
    initialValues?: Partial<Employee>
}

const EmployeeForm = (props: EmployeeFormProps) => {
    const { onSubmitted, initialValues } = props
    const { addEmployee, updateEmployee, error } = useEmployees()

    const onSubmit = (employee: Employee) => {
        if (!!employee._id) {
            return updateEmployee(employee._id, employee).then(() => {
                onSubmitted?.()
            })
        }
        return addEmployee(employee).then(() => {
            onSubmitted?.()
        })
    }

    return (
        <Form<Employee> onSubmit={onSubmit} initialValues={initialValues}>
            {(onChangeFor, values, errors) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("email")}
                            value={values.email}
                            error={errors.email}
                            required
                            type={"email"}
                            fullWidth
                            name={"email"}
                            label={"E-post"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("given_name")}
                            value={values.given_name}
                            error={errors.given_name}
                            fullWidth
                            name={"given_name"}
                            label={"Fornavn"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("family_name")}
                            value={values.family_name}
                            error={errors.family_name}
                            fullWidth
                            name={"family_name"}
                            label={"Etternavn"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneField
                            onChange={onChangeFor<string>("phone")}
                            value={values.phone}
                            error={errors.phone}
                            fullWidth
                            name={"phone"}
                            label={"Telefonnummer"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={onChangeFor<string>("position")}
                            value={values.position}
                            error={errors.position}
                            fullWidth
                            name={"position"}
                            label={"Stilling"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DatePicker
                            onChange={onChangeFor<Date>("birthday")}
                            value={values.birthday}
                            error={errors.birthday}
                            maxDate={Date.now()}
                            fullWidth
                            name={"birthday"}
                            label={"Bursdag"}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", marginTop: 12 }}>
                        <Typography color={"error"}>{error?.message}</Typography>
                        <SubmitButton errors={errors} float={"right"}>
                            Lagre
                        </SubmitButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    )
}

export default EmployeeForm
