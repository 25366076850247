import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import React, { useState } from "react"
import { OnChange } from "../../Form"
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField/TextField"

type TextFieldValue<V> = V | null

export type TextFieldProps<V> = Omit<MuiTextFieldProps, "onChange" | "error" | "onBlur"> & {
    onChange: OnChange<TextFieldValue<V>>
    error?: string
}

let idCount = 0
const TextField = <V extends string | number | undefined = number>(props: TextFieldProps<V>) => {
    const {
        name,
        label,
        required,
        multiline,
        variant,
        type,
        rows,
        rowsMax,
        fullWidth,
        InputProps,
        className,
        value,
        onChange,
        error,
    } = props
    const [touched, setTouched] = useState(false)
    const id = `TextField-${name}-${idCount++}`
    return (
        <FormControl required={required} variant={variant} fullWidth={fullWidth} error={touched && !!error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input
                id={id}
                className={className}
                onBlur={() => setTouched(true)}
                name={name}
                value={value || ""}
                type={type}
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                onChange={(e) =>
                    onChange((type === "number" ? parseInt(e.target.value, 10) : e.target.value) as TextFieldValue<V>)
                }
                {...InputProps}
            />
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

export default TextField
