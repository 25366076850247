import React from "react"
import { Outlet, Route, Routes } from "react-router-dom"
import routes, { ViewRoute } from "../../routes"
import PrivateRoute from "./PrivateRoute"
import { RouteProps } from "react-router"
import ViewWrapper from "../../components/ViewWrapper"

interface RouterProps {
    layout: string
}

class Router extends React.Component<RouterProps> {
    public renderRoute = (route: ViewRoute, key = 0, secure = false) => {
        const RouteComponent: React.ComponentType<RouteProps> = secure || route.secure ? PrivateRoute : Route
        return (
            <React.Fragment key={key}>
                <RouteComponent
                    element={<ViewWrapper routeConfig={route}>{route.element}</ViewWrapper>}
                    path={route.path}
                    key={key}
                />
                {route.views ? (
                    <RouteComponent element={<Outlet />} path={route.path + "/*"}>
                        {route.views.map((subRoute: ViewRoute, i: number) =>
                            this.renderRoute(subRoute, i, route.secure),
                        )}
                    </RouteComponent>
                ) : null}
            </React.Fragment>
        )
    }

    public render() {
        const layoutConfig = routes[this.props.layout]
        return (
            <Routes>
                {layoutConfig.views.map((viewRoute: ViewRoute, i) =>
                    this.renderRoute(viewRoute, i, layoutConfig.secure),
                )}
            </Routes>
        )
    }
}

export default Router
