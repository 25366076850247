import React, { useEffect, useState } from "react"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import ViewContent from "../../components/View/ViewContent"
import { useRoles } from "../../store/RoleStore"
import Table from "../../components/Table"
import { Checkbox, CircularProgress, fade, makeStyles, Theme } from "@material-ui/core"
import { Permission, usePermissions } from "../../store/PermissionStore"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
    },
    tablesContainer: {
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        overflowY: "hidden",
        marginTop: theme.spacing(4),
    },
    permissionsColumn: {
        backgroundColor: fade(theme.palette.secondary.dark, 0.6),
    },
    permissionsHead: {
        backgroundColor: fade(theme.palette.secondary.dark, 0.6),
    },
    tableRow: {
        height: 56,
    },
}))

const Roles = () => {
    const { getAllRoles, roles, addOrRemovePermissionsFromRole, ...roleStore } = useRoles()
    const { getAllPermissions, permissions, ...permissionStore } = usePermissions()
    const classes = useStyles()

    const [loadingPermissionAndRole, setLoadingPermissionAndRole] = useState<
        { roleId: string; permissionId: string } | undefined
    >()

    useEffect(() => {
        getAllRoles({ includePermissions: true })
        getAllPermissions()
    }, [getAllRoles, getAllPermissions])

    const changePermission = (roleId: string, permission: Permission, add: boolean) => {
        setLoadingPermissionAndRole({
            roleId: roleId,
            permissionId: permission._id,
        })
        addOrRemovePermissionsFromRole(roleId, [permission], add ? "ADD" : "REMOVE").then(() =>
            setLoadingPermissionAndRole(undefined),
        )
    }

    return (
        <View className={classes.root}>
            <ViewHeader title={"Roller"} />
            <ViewContent loading={roleStore.loading || permissionStore.loading} className={classes.tablesContainer}>
                <Table<Permission>
                    headProps={{ className: classes.permissionsHead }}
                    rowProps={{ className: classes.tableRow }}
                    key={0}
                    data={permissions}
                    config={[
                        {
                            title: "Tilgang",
                            getValue: (permission) => permission.description,
                        },
                    ]}
                />
                {roles.map((role, key) => (
                    <Table<Permission>
                        headProps={{ className: classes.permissionsHead }}
                        rowProps={{ className: classes.tableRow }}
                        key={key + 1}
                        data={permissions}
                        config={[
                            {
                                title: role.name,
                                getValue: (permission) => {
                                    const checked =
                                        role.permissions &&
                                        role.permissions.findIndex((perm) => permission._id === perm._id) >= 0
                                    const shouldLoad =
                                        loadingPermissionAndRole?.permissionId === permission._id &&
                                        loadingPermissionAndRole.roleId === role._id

                                    return shouldLoad ? (
                                        <CircularProgress size={16} />
                                    ) : (
                                        <Checkbox
                                            onChange={(e) => {
                                                changePermission(role._id, permission, e.target.checked)
                                            }}
                                            style={{ padding: 0 }}
                                            checked={checked}
                                        />
                                    )
                                },
                            },
                        ]}
                    />
                ))}
            </ViewContent>
        </View>
    )
}

export default Roles
