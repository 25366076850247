import React, { useEffect, useState } from "react"
import { useClients } from "../../store/ClientStore"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
    makeStyles,
} from "@material-ui/core"
import { useNavigate } from "react-router"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import { Add as AddIcon, MoreHoriz as DotsIcon, Delete as DeleteIcon } from "@material-ui/icons"
import ViewContent from "../../components/View/ViewContent"
import Table from "../../components/Table"
import { Client } from "../../store/ClientStore"
import Menu from "../../components/Menu"
import ClientForm from "../../forms/ClientForm"

const useStyles = makeStyles(() => ({
    clientLogo: {
        height: 42,
        width: 42,
        borderRadius: 84,
        objectFit: "cover",
    },
}))

const Clients = () => {
    const { getAllClients, clients, removeOneClient } = useClients()
    const [isNewClientDialogOpen, setIsNewClientDialogOpen] = useState<boolean>(false)
    const theme = useTheme()
    const navigate = useNavigate()
    const classes = useStyles()
    const isSmallSize = useMediaQuery(theme.breakpoints.down("xs"))

    useEffect(() => {
        getAllClients()
    }, [getAllClients])

    return (
        <>
            <View>
                <ViewHeader
                    title={"Klienter"}
                    actions={
                        <Fab
                            color={"primary"}
                            variant={"extended"}
                            onClick={() => setIsNewClientDialogOpen((oldValue) => !oldValue)}
                        >
                            <AddIcon />
                            <Typography>Legg til klient</Typography>
                        </Fab>
                    }
                />
                <ViewContent>
                    <Table<Client>
                        data={clients}
                        onClickRow={(client) => navigate(client._id)}
                        config={[
                            {
                                title: "",
                                getValue: (client) =>
                                    client.logo && (
                                        <img className={classes.clientLogo} alt={client.name} src={client.logo} />
                                    ),
                                width: 52,
                            },
                            {
                                title: "Navn",
                                getValue: (client) => client.name,
                            },
                            {
                                title: "E-post",
                                getValue: (client) => client.mail,
                            },
                            {
                                title: "Telefon",
                                getValue: (client) => client.phone,
                            },
                            {
                                title: "",
                                getValue: (client) => (
                                    <Menu size={"small"} Icon={DotsIcon}>
                                        <ListItem button onClick={() => removeOneClient(client._id)}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText>Slett</ListItemText>
                                        </ListItem>
                                    </Menu>
                                ),
                            },
                        ]}
                    />
                </ViewContent>
            </View>
            <Dialog
                fullWidth
                fullScreen={isSmallSize}
                open={isNewClientDialogOpen}
                onClose={() => setIsNewClientDialogOpen(false)}
            >
                <DialogTitle>Ny klient</DialogTitle>
                <DialogContent>
                    <ClientForm onSubmitted={() => setIsNewClientDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Clients
