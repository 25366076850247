import React, { useState } from "react"
import MuiPhoneNumber, { MuiPhoneNumberCountryData } from "material-ui-phone-number"
import { OnChange } from "../../Form"
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField/TextField"
const PERMITTED_LOCALES = ["nb-NO"]

type PhoneFieldProps = Omit<MuiTextFieldProps, "onChange" | "error" | "onBlur"> & {
    name: string
    defaultCountry?: string
    onChange: OnChange<string | null>
    error?: string
}

const PhoneField = (props: PhoneFieldProps) => {
    const { error, onChange, ...rest } = props
    const [touched, setTouched] = useState(false)
    return (
        <MuiPhoneNumber
            onBlur={() => setTouched(true)}
            fullWidth
            error={!!error && touched}
            helperText={error}
            defaultCountry={"no"}
            onEmptied={() => onChange(null)}
            onChange={(val: string, countryData?: MuiPhoneNumberCountryData) =>
                onChange(
                    countryData && val.replace(`+${countryData.dialCode}`, "").length <= 0
                        ? null
                        : val.replace(/ /g, ""),
                )
            }
            countryCodeEditable={false}
            onlyCountries={PERMITTED_LOCALES.map((locale) => locale.split("-")[1].toLowerCase())}
            {...rest}
        />
    )
}

export default PhoneField
