import React, { useEffect, useState } from "react"
import View from "../../components/View"
import ViewHeader from "../../components/View/ViewHeader"
import ViewContent from "../../components/View/ViewContent"
import Table from "../../components/Table"
import { Employee, useEmployees } from "../../store/EmployeeStore"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core"
import EmployeeForm from "../../forms/EmployeeForm"
import { useIsSmallSize } from "../../utils/hooks"
import { Add as AddIcon } from "@material-ui/icons"
import { useNavigate } from "react-router"
import Menu from "../../components/Menu"
import DotsIcon from "@material-ui/icons/MoreHoriz"
import DeleteIcon from "@material-ui/icons/Delete"

const Employees = () => {
    const { employees, getAllEmployees, removeOneEmployee } = useEmployees()
    const isSmallSize = useIsSmallSize()
    const [isNewEmployeeDialogOpen, setIsNewEmployeeDialogOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        getAllEmployees()
    }, [getAllEmployees])
    return (
        <>
            <View>
                <ViewHeader
                    title={"Ansatte"}
                    actions={
                        <Fab color={"primary"} variant={"extended"} onClick={() => setIsNewEmployeeDialogOpen(true)}>
                            <AddIcon />
                            <Typography>Legg til ansatt</Typography>
                        </Fab>
                    }
                />
                <ViewContent>
                    <Table<Employee>
                        data={employees}
                        onClickRow={(employee) => {
                            navigate(employee._id!)
                        }}
                        config={[
                            {
                                title: "Navn",
                                getValue: (employee) => [employee.given_name, employee.family_name].join(" "),
                            },
                            {
                                title: "Stilling",
                                getValue: (employee) => employee.position,
                            },
                            {
                                title: "E-post",
                                getValue: (employee) => employee.email,
                            },
                            {
                                title: "",
                                getValue: (employee) => (
                                    <Menu size={"small"} Icon={DotsIcon}>
                                        <ListItem button onClick={() => removeOneEmployee(employee._id!)}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText>Slett</ListItemText>
                                        </ListItem>
                                    </Menu>
                                ),
                            },
                        ]}
                    />
                </ViewContent>
            </View>
            <Dialog
                fullWidth
                fullScreen={isSmallSize}
                open={isNewEmployeeDialogOpen}
                onClose={() => setIsNewEmployeeDialogOpen(false)}
            >
                <DialogTitle>Ny ansatt</DialogTitle>
                <DialogContent>
                    <EmployeeForm onSubmitted={() => setIsNewEmployeeDialogOpen(false)} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Employees
