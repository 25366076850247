import React, { ReactNode } from "react"
import { makeStyles, Theme, Typography } from "@material-ui/core"
import cn from "classnames"

export interface ViewHeaderProps {
    className?: string
    actions?: ReactNode | ReactNode[]
    title?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        marginBottom: theme.spacing(1),
    },
    actions: {
        display: "flex",
    },
}))

const ViewHeader = (props: ViewHeaderProps) => {
    const { actions, title, className } = props
    const classes = useStyles()

    return (
        <div className={cn(classes.root, className)}>
            {title && <Typography variant={"h1"}>{title}</Typography>}
            {actions && <div className={classes.actions}>{actions}</div>}
        </div>
    )
}

export default ViewHeader
