import norwegianLocale from "date-fns/locale/nb"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import React, { ReactNode } from "react"
import PickerUtils from "./PickerUtils"

export interface PickerProviderProps {
    children: ReactNode | ReactNode[]
}

const PickerProvider = (props: PickerProviderProps) => {
    const { children } = props
    return (
        <MuiPickersUtilsProvider utils={PickerUtils} locale={norwegianLocale}>
            {children}
        </MuiPickersUtilsProvider>
    )
}

export default PickerProvider
