import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react"
import createAuth0Client, {
    Auth0ClientOptions,
    getIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    PopupConfigOptions,
    PopupLoginOptions,
    RedirectLoginOptions,
} from "@auth0/auth0-spa-js"
import decodeJwt from "jwt-decode"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import { CircularProgress } from "@material-ui/core"
import { Auth0UserProfile } from "auth0-js"
import axios from "axios"
import { getFirebaseToken } from "../api/firebaseApi"
import { firebaseAuth } from "../firebase"

export interface AuthAppState {
    targetUrl: string
}

export interface Auth0ProviderProps extends Auth0ClientOptions {
    children: ReactNode | ReactNode[]
    onRedirectCallback: (appState: AuthAppState) => void
    useFirebase?: boolean
}

export interface Auth0ContextType {
    isAuthenticated: boolean
    user?: Auth0UserProfile
    permissions: string[]
    roles: string[]
    loading: boolean
    popupOpen: boolean
    firebaseToken: null | string
    loginWithPopup: (config?: PopupLoginOptions, popupConfigOptions?: PopupConfigOptions) => void
    getIdTokenClaims: (config?: getIdTokenClaimsOptions) => Promise<IdToken>
    loginWithRedirect: (config?: RedirectLoginOptions) => Promise<void>
    getTokenSilently: (config?: GetTokenSilentlyOptions) => Promise<string>
    getTokenWithPopup: (config?: GetTokenWithPopupOptions) => Promise<string>
    logout: (config?: LogoutOptions) => void
}

export const Auth0Context = React.createContext<Auth0ContextType | null>(null)
export const useAuth0 = () => useContext<Auth0ContextType | null>(Auth0Context) as Auth0ContextType

export const Auth0Provider = ({
    children,
    onRedirectCallback,
    domain,
    client_id,
    redirect_uri,
    audience,
    scope,
    useFirebase,
}: Auth0ProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [user, setUser] = useState<Auth0UserProfile>()
    const [auth0Client, setAuth0] = useState<Auth0Client>()
    const [loading, setLoading] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)
    const [firebaseToken, setFirebaseToken] = useState<string | null>(null)
    const [permissions, setPermissions] = useState<string[]>([])
    const [roles, setRoles] = useState<string[]>([])

    useEffect(() => {
        createAuth0Client({ scope, domain, client_id, redirect_uri, audience }).then((auth0InHook: Auth0Client) => {
            setAuth0(auth0InHook)

            auth0InHook.isAuthenticated().then((_isAuthenticated) => {
                setIsAuthenticated(_isAuthenticated)
                if (_isAuthenticated) {
                    setLoading(true)
                    auth0InHook
                        .getTokenSilently()
                        .then(
                            (token): Promise<Auth0UserProfile> => {
                                const decodedToken: {
                                    permissions?: string[]
                                    [key: string]: string[] | undefined
                                } = decodeJwt(token)
                                setPermissions(decodedToken.permissions || [])
                                setRoles(decodedToken[`${audience}/roles`] || [])

                                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
                                return auth0InHook.getUser()
                            },
                        )
                        .then((_user) => {
                            setUser(_user)
                            if (useFirebase) {
                                getFirebaseToken()
                                    .then((_firebaseToken) => {
                                        setFirebaseToken(_firebaseToken)
                                        firebaseAuth()
                                            .signInWithCustomToken(_firebaseToken)
                                            .catch((err) => {
                                                console.log("Could not sign in with custom firebase token" + err)
                                            })
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        console.log("Could not get custom firebase token")
                                    })
                            }
                            setLoading(false)
                        })
                        .catch(() => {
                            console.log("Could not fetch auth0 user")
                        })
                } else {
                    setLoading(false)
                }
            })
            if (window.location.search.includes("code=")) {
                auth0InHook.handleRedirectCallback().then(({ appState }) => {
                    if (onRedirectCallback) {
                        onRedirectCallback(appState)
                    }
                })
            }
        })
    }, [onRedirectCallback, client_id, domain, redirect_uri, audience, scope, useFirebase])

    const loginWithPopup = useCallback(
        (params = {}) => {
            setPopupOpen(true)
            if (auth0Client) {
                auth0Client
                    .loginWithPopup(params)
                    .then(() => {
                        return auth0Client.getUser()
                    })
                    .then((user: Auth0UserProfile) => {
                        setUser(user)
                        setIsAuthenticated(!!user)
                        setPopupOpen(false)
                    })
            }
        },
        [auth0Client],
    )

    return auth0Client && !loading ? (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                permissions,
                roles,
                loading,
                popupOpen,
                firebaseToken,
                loginWithPopup,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (p) =>
                    auth0Client.logout({
                        returnTo: window.location.origin,
                        ...p,
                    }),
            }}
        >
            {children}
        </Auth0Context.Provider>
    ) : (
        <CircularProgress />
    )
}

export default Auth0Provider
